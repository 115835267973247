.tab-section .nav-tabs {
    position: relative;
    top: -1px;
}
.tabs-xs h2, .tabs-xs .season {
    font-size: 24px;
}
.tabs-xs.tab-content{
    font-size: 15px;
}
@media screen and (min-width: 768px){
    .tabs-xs .tab-content {
        max-width: 250px;
        font-size: 16px;
    }
    .tabs-xs {
        padding-bottom: 30px;
    }
    .tabs-xs .tab-content h2 {
        height: 75px;
    }
    .tab-content span.italic {
        height: 22px;
        display: block;
    }
}