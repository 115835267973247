.header-img {
    position: relative;
    max-width: 1920px;
}
.header-img .breadcrumb {
    position: absolute;
    z-index: 1;
}

@media screen and (max-width: 767px) {
    .header-img .breadcrumb{
        margin: 0 15px;
    }
}

.header-img--text {
    position: absolute;
    width: 100%;
    bottom: 10px;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .header-img .breadcrumb {
        top: 20px;
    }
    .header-img--text {
        bottom: 10%;
    }
    .header-img--text--weather {
        bottom: 6%;
    }
    .header-img__overlay {
        position: absolute;
        width: 100%;
        bottom: 30%;
        z-index: 1;
    }
    .header-img__overlay--weather {
        bottom: 33%;
    }
    .header-img__overlay img {
        max-width: 300px;
    }
    .header-img--text--weather .header-img--text__hl {
        margin-bottom: 20px;
    }
}
.header-img--text.header-img--text--centered {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
}
@media screen and (min-width: 1200px){
    .header-img__overlay img {
        max-width: 450px;
    }
    .header-img__overlay--weather {
        bottom: 26%;
    }
}

.header-img__overlay__icon {
    font-size: 120px;
}
@media screen and (min-width: 1200px){
    .header-img__overlay__icon {
        font-size: 200px;
    }
}

.header-img--has-logo {
    margin-bottom: 60px;
}
@media screen and (min-width: 768px) {
    .header-img--has-logo {
        margin-bottom: 100px;
    }
}
.header-img--has-logo-lg {
    margin-bottom: 80px;
}
@media screen and (min-width: 768px) {
    .header-img--has-logo-lg {
        margin-bottom: 140px;
    }
}
.header-img__logo {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    padding: 0 30px;
    transform: translateY(-20px);
}
@media screen and (min-width: 768px) {
    .header-img__logo {
        transform: translateY(-50%);
    }
}
.header-img__logo__img {
    margin: 0 auto;
    max-height: 80px;
    width: auto;
}
@media screen and (min-width: 768px) {
    .header-img__logo__img {
        max-height: 150px;
    }
}

.header-img__logo--lg .header-img__logo__img {
    max-height: 100px;
}
@media screen and (min-width: 768px) {
    .header-img__logo--lg .header-img__logo__img {
        max-height: 220px;
    }
}