.panel.panel-default {
    background: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    border-top: 2px solid rgba(230, 230, 230, 0.59);
}
.panel-default .panel-heading {
    padding: 0;
    background: transparent;
    border-radius: 0;
}
.panel-default .panel-title a {
    padding: 10px 30px 10px 15px;
    display: block;
    position: relative;
    background: rgba(230, 230, 230, 0.59);
    font-size: 16px;
    line-height: calc(24/20);
    font-family: 'SourceSansPro-Regular';
    font-weight: 400;
    color: #404040;
}
@media screen and (min-width: 768px) {
    .panel-default .panel-title a {
        padding: 16px 25px;
        font-size: 20px;
    }
}
.panel-title a.collapsed {
    background: transparent;
}
.panel-title .panel-title__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: transform 200ms ease-in-out;
}
@media screen and (min-width: 768px) {
    .panel-title .panel-title__icon {
        right: 20px;
    }
}
a.collapsed .panel-title__icon {
    transform: translateY(-50%);
}
.panel-default .panel-body,
.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 0;
}
.panel-default .panel-body {
    padding: 10px 15px;
    font-size: 15px;
    line-height: calc(25/18);
    color: #666666;
    font-family: 'SourceSansPro-Light', sans-serif;
    font-weight: 400;
    background: rgba(230, 230, 230, 0.59);
}
@media screen and (min-width: 768px) {
    .panel-default .panel-body {
        padding: 20px 25px 60px;
        font-size: 18px;
    }
}
.panel-group .panel-default+.panel-default {
    margin-top: 0;
}