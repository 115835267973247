@media screen and (max-width: 767px) {
    .navbar-main {
        position: static;
    }
    .navbar-main .navbar-header {
        height: 60px;
        box-shadow: 0 1px 4px rgba(0,0,0,.1);
    }
    .navbar-brand>img {
        max-width: 170px;
    }
    .nav>li>a:focus, .nav>li>a:hover {
        background: transparent;
    }

    /*Nav Collapse*/
    .navbar-collapse {
        position: absolute;
        margin: 0 !important;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: #f2f2f2;
    }
    .navbar-nav {
        margin: 15px 0;
    }
    .navbar-nav li {
        padding: 2px 10px;
    }
    .navbar-nav li.is-open {
        border: 1px solid;
        border: 1px solid #c3c3c5;
    }
    .navbar-nav li.is-open>a {
        border-bottom: 1px solid #c3c3c5;
    }
    .navbar-nav>li>a {
        color: #666666;
        font-size: 18px;
    }
    .navbar-nav .is-open .main-nav__dropdown {
        display: block;
    }

    .navbar-nav .subnav-toggle {
        padding: 10px;
        z-index: 1;
        position: relative;
    }
    .main-nav__dropdown .main-nav__dropdown__teaser-list {
        padding: 0;
    }
    .main-nav__dropdown__teaser-list li + li {
        margin-top: 10px;
    }
    .subnav-block {
        padding: 6px 0px 6px 15px;
    }
    .subnav-block ul {
        margin-top: 8px;
        display: none;
    }
    .subnav-block.is-open ul {
        display: block;
    }
    .subnav-block .subnav-toggle {
        padding: 4px 15px 4px 15px;
    }
    .subnav-block ul {
        list-style: none;
        padding: 0;
    }
    .subnav-block a {
        color: #292929;
    }

    /*Toggle Button*/
    .navbar-main .navbar-toggle {
        margin: 0;
        padding: 13px 15px;
        color: #666666;
        font-size: 28px;
        line-height: 1;
        transition: 200ms;
        transform: rotate(-180deg);
    }

    .navbar-main .navbar-toggle .icon-bar {
        width: 30px;
        background: #666666;
        height: 5px;
    }
    .navbar-toggle .icon-bar+.icon-bar {
        margin-top: 5px;
    }
    .navbar-main .navbar-toggle.is-open {
        transform: rotate(0deg);
    }
    .navbar-main .navbar-toggle.is-open .icon-bar {
        display: none;
    }
    .navbar-main .navbar-toggle.is-open:before {
        font-family: iconfont;
        content: "\E014";
    }
    .navbar-nav .subnav-toggle.icon-zoom-out {
        font-size: 15px;
    }
    .navbar-nav__lang {
        padding-left: 15px;
    }
}
