.detail-table{
    padding-left: 20px;

}
.detail-table__label{
    font-size: 18px;
    text-align: left;
    padding-left: 0;
    padding-bottom: 5px;
}

.divider-left.detail-table__text{
    text-align: left;
    padding-left: 0;
    padding-bottom: 10px;
    border: none;
}
.detail-table__icon>.icon-anreise{
    position: relative;
    width: 40px;
    background: #fff;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
}

.detail-table__icon:after{
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background: #c3c3c5;
    left: 0;
    top: 12px;
    z-index: -1;
}

@media screen and (min-width: 768px) {
    .detail-table__label{
        font-size: 15px;
        font-family: 'SourceSansPro-Semibold', sans-serif;
        text-align: right;
        padding-left: 10px;
        padding-bottom: 0;
        border-left: 0;
    }

    .divider-left.detail-table__text{
        padding-left: 30px;
        padding-bottom: 15px;
        border-left: 1px solid #c3c3c5;

    }
    .detail-table__icon{
        text-align: center;
        padding-left: 10px;
        left: 0;
    }
    .detail-table__icon>.icon-anreise{
        width: auto;
        background: transparent;
        display: inline;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .detail-table__icon:after{
        display: none;
    }
}

