.form-control--special{
    border: none;
    border-bottom:1px solid #c3c3c5;
}
.form-control--special:focus{
    border-color: #229b09;
}
.form-control-label {
    position:absolute;
    pointer-events:none;
    left:10px;
    transition:0.2s ease all;
    font-size: 18px;
    transform: translateY(-30px) translateZ(0);
    transform-origin: left center;
}
.form-control--special[readonly] {
    background: none;
}
/*Active*/
.form-control--special.is-not-empty ~ .form-control-label, .form-control--special:focus ~ .form-control-label, .js-datepicker ~ .form-control-label, .counter .form-control-label{
    color: #229b09;
    transform: translateY(-60px) scale(0.8);
}
/*Line*/
.form-control-line {
    position:absolute;
    display:block;
    left: 10px;
    right: 10px;
    height: 2px;
    bottom: 1px;
    background:#229b09;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
    transform: scaleX(0);
}
.form-control--special:focus ~ .form-control-line {
    transform: scaleX(1);
}

.form-control-item {
    margin: 25px 0;
}
.form-control--head {
    border: 1px solid #c3c3c5;
    padding: 10px;
    margin-bottom: 20px;
}

textarea.form-control--special {
    height: 40px;
}
textarea.form-control--special ~ .form-control-label {
    top: 40px;
}

/*Counter*/
.counter .icon {
    border: 1px solid #c3c3c5;
    top: 5px;
    padding: 6px;
    position: absolute;
    background: #fff;
}
.counter .icon-zoom-in {
    right: 21px;
}
.counter-input {
    border: 0;
    text-align: center;
    padding-left: 0;
}
.counter-input::-webkit-outer-spin-button,
.counter-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.counter-plus, .counter-minus {
    cursor: pointer;
    font-size: 17px;
}

@media screen and (min-width: 768px){
    .form-control-item {
        margin: 18px 0;
    }
    .form-control--submit {
        position: relative;
        bottom: -90px;
    }
    .counter {
        margin-left: 45px;
    }
}