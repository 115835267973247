.theme-pfahlbauten .btn-primary{
    background: #85aab3;
    border-color: #85aab3;
}
.theme-pfahlbauten .btn-primary:focus,
.theme-pfahlbauten .btn-primary:hover {
    background: #7eaeba;
}
.theme-pfahlbauten .teaser__head .label-top-left {
    background: #85aab3;
}