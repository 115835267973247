.info-link {
    display: flex;
    align-items: center;
}
.info-link + .info-link {
    margin-top: 16px;
}
.info-link__icon {
    min-width: 35px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #F1A018;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}
.info-link__link {
    color: #F1A018;
    position: relative;
    display: block;
}
@media screen and (min-width: 768px) {
    .info-link__link {
        display: inline-block;
        padding-left: 7px;
    }
    .info-link__link:before {
        content: '|';
        position: absolute;
        top: 0;
        left: 0;
        color: #666;
    }
}
.info-link__link:hover {
    text-decoration: underline;
}
.info-link--warning .info-link__icon {
    background: #9B2689;
}
.info-link--warning .info-link__link {
    color: #9B2689;
}

.flex-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -16px;
    justify-content: space-between;
}
.flex-list__item {
    width: 100%;
    margin-top: 16px;
}
@media screen and (min-width: 768px) {
    .flex-list__item {
        max-width: 48%;
    }
}