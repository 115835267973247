@font-face {
    font-family: "iconfont-weather";
    src: url('/static/weather-icons/font/iconfont-weather.eot?#iefix') format('eot'),
        url('/static/weather-icons/font/iconfont-weather.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.weather-icon {
    font-family: "iconfont-weather";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.weather-icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.weather-icon-2x { font-size: 2em; }
.weather-icon-3x { font-size: 3em; }
.weather-icon-4x { font-size: 4em; }
.weather-icon-5x { font-size: 5em; }
.weather-icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.weather-icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.weather-icon-ul > li {
    position: relative;
}
.weather-icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.weather-icon-li.weather-icon-lg {
    left: -1.85714286em;
}

.weather-icon-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.weather-icon-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.weather-icon-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.weather-icon-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.weather-icon-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
:root .weather-icon-rotate-90,
:root .weather-icon-rotate-180,
:root .weather-icon-rotate-270,
:root .weather-icon-flip-horizontal,
:root .weather-icon-flip-vertical {
    filter: none;
}

.weather-icon-elements:before { content: "\E001" }
    .weather-icon-1:before { content: "\E002" }
    .weather-icon-10:before { content: "\E003" }
    .weather-icon-11:before { content: "\E004" }
    .weather-icon-12:before { content: "\E005" }
    .weather-icon-13:before { content: "\E006" }
    .weather-icon-14:before { content: "\E007" }
    .weather-icon-15:before { content: "\E008" }
    .weather-icon-16:before { content: "\E009" }
    .weather-icon-17:before { content: "\E00A" }
    .weather-icon-18:before { content: "\E00B" }
    .weather-icon-19:before { content: "\E00C" }
    .weather-icon-2:before { content: "\E00D" }
    .weather-icon-3:before { content: "\E00E" }
    .weather-icon-4:before { content: "\E00F" }
    .weather-icon-5:before { content: "\E010" }
    .weather-icon-6:before { content: "\E011" }
    .weather-icon-7:before { content: "\E012" }
    .weather-icon-9:before { content: "\E014" }
    