.section {
    position: relative;
}
.section__head {
    overflow: visible;
}
.section__head--bar {
    position: absolute;
    top: -32px;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 5px;
}
.section__head--bar ul {
    margin-bottom: 0;
}
.section__head--bar ul li {
    padding-right: 8px;
    padding-left: 8px;
}
.dropdown-share-toggle {
    cursor: pointer;
}
.dropdown-share-menu {
    right: 0;
    /*width: 150px;*/
    top: 25px;
    left: auto;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.37);
    margin: 0;
    transform: scale(0);
    transform-origin: top right;
    transition: 200ms;
    display: block;
    border: 0;
    border-radius: 0;
    padding: 0 10px;
    color: #666;
}
.open>.dropdown-share-menu{
    transform: scale(1);
}
.dropdown-share-menu.dropdown-share-menu li {
    padding: 10px 20px;
}
.section__head--text {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}
.section__content {
    padding-top: 20px;
    padding-bottom: 20px;
}
.section__cta {
    position: absolute;
    width: 100%;
    bottom: -20px;
}
.section__content--fixed {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
}
.section__content .fz100 {
    line-height: 60px;
}
.section.section__map-list {
    padding-top: 70px;
}
.unesco {
    padding: 8px;
    width: 80px;
    height: 80px;
}
.bodenseecard-badge {
    width: 100px;
}
.section__head--text>.arrange__item--bottom--xs>h1 {
    font-size: 28px;
}
.section__head--center {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
@media screen and (min-width: 768px){
    .section {
        box-shadow: 0 0 10px 1px rgba(0,0,0,.1);
    }
    .section__head:hover .img-overlay--black:after {
        opacity: 0;
    }
    .section__head--text {
        height: 100%;
    }
    .section__head--text>.arrange__item--bottom--xs>h1 {
        font-size: 55px;
    }
    .section__content {
        padding: 40px;
    }
    .section__content--text {
        max-width: 700px;
    }
    .section__head--more {
        opacity: 0;
        transition: 300ms ease-in-out;
        transform: translateZ(0);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .section__head--more.is-open{
        opacity: 1;
    }
    .section__head--bar {
        top: 0;
    }
    .section__head--bar ul li {
        padding-right: 10px;
        padding-left: 10px;
    }
    .section-more {
        position: relative;
        bottom: 28px;
        height: 60px;
        z-index: 2;
    }
    .section-more--btn {
        cursor: pointer;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        box-shadow: 0 0px 10px 1px rgba(0,0,0,.1);
        margin: 0 auto;
        position: relative;
    }
    .section-more--btn .icon-open {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -15px;
        margin-left: -15px;
        transition: 200ms ease-in;
        font-size: 30px;
        transform: translateZ(0);
    }
    .section-more.is-open .bg-green-light {
        background: #fff;
    }
    .section-more.is-open .icon-open {
        transform: rotate(45deg) translateZ(0);
    }
    .section-more.is-open .bg-green-light .icon-open {
        color: #73be8c;
    }
    .section-more.is-open .bg-white .icon-open {
        color: #fff;
    }
    .section-more.is-open .bg-white {
        background: #00a078;
    }
    .unesco {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
    .bodenseecard-badge {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .unesco + .bodenseecard-badge {
        right: 116px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px){
    .section__head--text .arrange__item--xs .light.mb20 {
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 1400px) {
    .section__content--fixed {
        padding: 20px 80px;
    }
}