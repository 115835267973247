.container-booking-start-slide{
    position: relative;
}

.booking-start-slide__select-city .content-block.d-ib:last-child{
    margin-bottom: 0;
}

.booking-start-slide__select-overlay>.row>.col{
    z-index: 1;
}
.booking-start-slide__select-overlay .select-overlay__info-box{
    margin: 30px 0;
    background: #fff;
    box-shadow: 0 0 6px 0 #dbdbdb;
}

.select-city__select-form,
.select-region__select-form{
    position: relative;
    width: auto;
}


.select-city__link-list ul {
    list-style: none;
    padding: 0;
}
.select-city__link-list ul li {
    position: relative;
    padding-left: 25px;
}
.select-city__link-list ul li a{
    color: #009bdc;
}
.select-city__link-list ul li a:hover{
    text-decoration: underline;
}
.select-city__link-list ul li:before {
    content: '';
    border: 3px solid #009BDC;
    position: absolute;
    left: 8px;
    top: 9px;
}
.select-region__map{
    position: relative;
    top: -60px;

}

.select-region__map svg g:not(.map__no-hover):hover>path,
.select-region__map svg g:not(.map__no-hover):focus>path{
    fill: #d1d1d1;
    cursor: pointer;
}
.select-region__map svg g:not(.map__no-hover):visited>path{
    fill: #e6e6e6;
}

.select-region__map svg g:focus{
    outline: none;
}

.select-region__map svg g.info-active{
    fill: #d1d1d1;
}

.weather-map svg g:focus {
    outline: none;
}

.select-region__map svg g text{
    cursor: pointer;
}

.select-overlay__info-box{

}
.select-overlay__info-box .icon-close{
    position: absolute;
    cursor: pointer;
    top: -30px;
    padding: 18px;
    right: 50%;
    transform: translateX(30px);
    border-radius: 100%;
    z-index:1;
    background: #009bdc;
    box-shadow: 0 2px 3px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2);
}

.info-box__head{
    position: relative;
}
.info-box__head .img-overlay--black:hover:after{
    opacity: 0.5;
}
.info-box__head .head__hl{
    position: absolute;
    bottom: 0;
    padding: 0 30px;
}

.info-box__body{
    padding: 15px 20px;
}

.info-box__body .body__descpription{
    margin-bottom: 20px;
}

.info-box__body .btn-booking{
    width: 100%;
    padding: 11px 10px;
    font-size: 22px;
    text-transform: none;
    font-weight: 700;
    margin-bottom: 25px;
}

.info-box__body .btn-booking:focus,
.info-box__body .btn-booking:active{
    color: #b40a19;
}


.info-box__body .btn-booking .icon-bed{
    position: relative;
    top: 4px;
    right: 0;
    font-size: 26px;
    margin-right: 10px;
}
.info-box__body .btn-booking .btn-booking__text{
    margin-right: 10px;
    width: 80%;
    display: inline-block;
    font-size: 18px;
    white-space: normal;
    text-align: left;
    float: left;
    position: relative;
    top: 0;
}



.info-box__body .btn-booking .icon-arrow{
    position: absolute;
    display: inline-block;
    top: 0;
    bottom: 0;
    height: 35px;
    margin: auto;
    right: 10px;
    font-size: 15px;
    background: #b40a19;
    color: #fff;
    padding: 10px 14px;
    border-radius: 18px;
}

.info-box__body .body__top-destinations{
    margin-bottom: 20px;
}
.info-box__body .body__top-destinations ul li{
    float: left;
    margin-right: 1px;
    margin-bottom: 1px;
}


@media screen and (min-width: 768px) {
    .booking-start-slide__select-overlay>.row>.col{
        position: absolute;
        top: 60px;
    }
    .booking-start-slide__select-overlay .select-overlay__info-box {
        margin: 0;
    }

    .select-region__map svg{
        width: 100%;
        height: auto;
    }

    .select-city__select-form,
    .select-region__select-form{
        width: 390px;
    }
    .select-city__select-form .form-control.select,
    .select-region__select-form .from-control.select{
        height: 50px;
    }
    .select-city__select-form .form-control.select~.icon-open,
    .select-region__select-form .form-control.select~.icon-open{
        bottom: 19px;

    }

    .info-box__body{
        padding: 20px 30px;
    }

    .info-box__body .btn-booking{
        padding: 11px 20px;
    }

    .info-box__body .btn-booking .icon-bed{
        height: 26px;
        width: 35px;
        position: absolute;
        display: inline-block;
        top: 0;
        bottom: 0;
        left: 20px;
        margin: auto 0;
    }
    .info-box__body .btn-booking .btn-booking__text{
        font-size: 22px;
        width: 75%;
        margin-left: 10px;
        text-align: center;
        float:none;
    }

    .info-box__body .btn-booking .icon-arrow{
        left: auto;
        right: 20px;
    }

    .booking-start-slide__select-region{
        margin-bottom: -70px;
    }

}

@media screen and (min-width: 1200px) {
    .info-box__body .btn-booking .icon-bed{
        position: relative;
        margin: 0;
        float: left;
        left: 0;
        top: 5px;
    }

    .info-box__body .btn-booking .btn-booking__text{
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
        top: 2px;
        float: left;
        text-align: left;
    }
    .info-box__body .btn-booking .icon-arrow{
        position: relative;
        right: 0;
        float: right;
        left: 0;
    }

}
