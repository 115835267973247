.slide {
    padding: 30px 0;
}
@media screen and (min-width: 768px){
    .slide {
        padding: 50px 0;
    }
}
.slide--padding-top-lg {
    padding: 50px 0 30px;
}
@media screen and (min-width: 768px){
    .slide--padding-top-lg {
        padding: 70px 0 50px;
    }
}

.slide-lg {
    padding: 50px 0;
}
@media screen and (min-width: 768px){
    .slide-lg {
        padding: 70px 0;
    }
}

.slide-sm {
    padding: 20px 0;
}
@media screen and (min-width: 768px){
    .slide-sm {
        padding: 30px 0;
    }
}


.slide--no-bottom-padding.slide--no-bottom-padding {
    padding-bottom: 0;
}

.slide-text-block {
    color: #666;
    font-size: 16px;
    line-height: calc(25/18);
}
@media screen and (min-width: 768px){
    .slide-text-block {
        font-size: 18px;
    }
}

@media screen and (max-width: 767px){
    .slide__block {
        margin-bottom: 20px;
    }
}
.slide + footer.mt20 {
    margin-top: 0;
}

.slide--bordered-top {
    border-top: 1px solid #C3C3C5;
}