body {
    font-family: 'SourceSansPro-Regular', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: #404040;
}
html {
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    html {
        font-size: 15px;
    }
}

h1, h2, h3, h4 {
    margin-top: 0;
}

h1, .h1 { font-size: 31px; font-weight: 400; font-family: 'SourceSansPro-Semibold'; margin-bottom: 0;}
h2, .h2 { font-size: 22px; margin-bottom: 20px;}
h3, .h3 { font-size: 17px;}
h5, .h5 { font-size: 15px; }
h6, .h6 { font-size: 1em; }

@media screen and (min-width: 768px) {
    h1, .h1 {font-size: 70px;}
    h2 {font-size: 40px;}
    h3 {font-size: 20px;}
}

/*Font-sizes*/
.fz14 { font-size: 13px; }
.fz18 { font-size: 15px; }
.fz20 { font-size: 16px; }
.fz24 { font-size: 18px; }
.fz30 { font-size: 20px; }
.fz34 { font-size: 26px; }
.fz100{ font-size: 90px; }

@media screen and (min-width: 768px) {
    .fz10 { font-size: 10px; }
    .fz14 { font-size: 14px; }
    .fz20 { font-size: 18px; }
    .fz24 { font-size: 20px; }
    .fz30 { font-size: 22px; }
    .fz34 { font-size: 30px; }
}

@media screen and (min-width: 1200px) {
    .fz18 { font-size: 18px; }
    .fz20 { font-size: 20px; }
    .fz24 { font-size: 24px; }
    .fz30 { font-size: 30px; }
    .fz34 { font-size: 34px; }
    .fz100{ font-size: 100px; }
}

.font-amatic {
    font-family:'AmaticSC-Bold', sans-serif;
    font-weight: 400;
}