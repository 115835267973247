.blog-slide {
    position: relative;
    margin: 50px 0 40px;
}
@media screen and (min-width: 768px) {
    .blog-slide {
        margin: 100px 0 80px;
    }
}
.blog-slide__btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    color: #fff;
    padding: 15px 20px;
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
}
@media screen and (min-width: 768px) {
    .blog-slide__btn {
        padding: 15px 73px;
    }
}
.blog-slide__btn:hover {
    color: #fff;
}
.blog-slide__content {
    padding: 0 20px;
}
@media screen and (min-width: 768px) {
    .blog-slide__content {
        padding: 0 10%;
    }
}
@media screen and (min-width: 1200px) {
    .blog-slide__content {
        padding: 0 20%;
    }
}


/*colors*/
.blog-slide--blue {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #0088d2 0%, #009bdc 100%);}
.blog-slide--dark-blue {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #076582 0%, #1a99b5 100%);}
.blog-slide--turquoise {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #098585 0%, #3cbaba 100%);}
.blog-slide--dark-green {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #067e5b 0%, #0ba785 100%);}
.blog-slide--green {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #579c6c 0%, #8ac69f 100%);}
.blog-slide--light-blue {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #4f7e9b 0%, #84b0c7 100%);}
.blog-slide--purple {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #5f6398 0%, #9296c3 100%);}
.blog-slide--grey-blue {background-image: url("/static/img/pattern/pattern-bg.png"), linear-gradient(180deg, #85AAB3 0%, #B6D0D5 100%);;}
.blog-slide--blue .blog-slide__btn {background: #005faf;}
.blog-slide--dark-blue .blog-slide__btn {background: #066381;}
.blog-slide--turquoise .blog-slide__btn {background: #068181;}
.blog-slide--dark-green .blog-slide__btn {background: #067e5b;}
.blog-slide--green .blog-slide__btn {background: #067e5b;}
.blog-slide--light-blue .blog-slide__btn {background: #4e7d9a;}
.blog-slide--purple .blog-slide__btn {background: #5d6196;}
.blog-slide--grey-blue .blog-slide__btn {background: #85aab3;}

/*hover colors*/
.blog-slide--blue .blog-slide__btn:hover {background: #0580c2;}
.blog-slide--dark-blue .blog-slide__btn:hover {background: #1b718b;}
.blog-slide--turquoise .blog-slide__btn:hover {background: #178686;}
.blog-slide--dark-green .blog-slide__btn:hover {background: #0d8f70;}
.blog-slide--green .blog-slide__btn:hover {background: #579c6c;}
.blog-slide--light-blue .blog-slide__btn:hover {background: #648ca4;}
.blog-slide--purple .blog-slide__btn:hover {background: #7d81ad;}
.blog-slide--grey-blue .blog-slide__btn:hover {background: #7eaeba;}