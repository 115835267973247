.table>thead>tr>th, .wysiwyg table>thead>tr>th{
    font-weight: 400;
    font-family: 'SourceSansPro-Semibold';
    border-bottom: 1px solid #87878c;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td,
.table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th,
.wysiwyg>table>tbody>tr>td, .wysiwyg>table>tbody>tr>th,
.wysiwyg>table>tfoot>tr>td, .wysiwyg>table>tfoot>tr>th,
.wysiwyg>table>thead>tr>td{
    border-top: 1px solid #87878c;
}
.table-responsive {
    border: 0;
}
.table.vertical-thead tbody th, .wysiwyg>table>tbody>tr>th {
    background: #fafafa;
    padding-left: 20px;
}

.wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 20px;
}
.wysiwyg>table>tbody>tr>td, .wysiwyg>table>tbody>tr>th,
.wysiwyg>table>tfoot>tr>td, .wysiwyg>table>tfoot>tr>th,
.wysiwyg>table>thead>tr>td, .wysiwyg>table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
}

@media screen and (max-width: 767px) {
    .wysiwyg table {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}