.wysiwyg {
    line-height: 24px;
}
.wysiwyg a {
    color: #009bdc;
}
.wysiwyg a:hover {
    color: #0082a5;
}
.wysiwyg h1, .wysiwyg h2 {
    text-transform: none;
}
.wysiwyg h1{
    font-size: 30px;
    font-weight: 400;
    font-family: 'SourceSansPro-Light';
}
.wysiwyg h2 {
    font-size: 26px;
    color: #9a9a9a;
    font-weight: 400;
}
.wysiwyg h3 {
    font-weight: 400;
    font-family: 'SourceSansPro-Semibold';
    font-size: 20px;
}
.wysiwyg ul {
    list-style: none;
    padding: 0;
}
.wysiwyg ul li {
    position: relative;
    padding-left: 25px;
}
.wysiwyg ul li:before {
    content: '';
    border: 3px solid #009BDC;
    position: absolute;
    left: 8px;
    top: 9px;
}
.wysiwyg strong {
    font-family: 'SourceSansPro-Semibold';
    font-weight: normal;
}
.two-columns {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
}
@media screen and (min-width: 768px){
    .wysiwyg h1 {
        font-size: 70px;
    }
    .wysiwyg h2 {
        font-size: 34px;
    }
}
.wysiwyg img {
    max-width: 100%;
    height: auto;
}