.portal-slider:hover .img-overlay--black:after {
    opacity: 0;
}
.portal-slider--item {
    position: relative;
}
.portal-slider--claim {
    position: absolute;
    width: 100%;
    top: 30%;
}
.portal-slider--claim h1 {
    margin-bottom: 0;
}
.portal-slider--claim .btn {
    padding: 8px 25px;
}

.slick-dots {
    list-style: none;
    padding: 0 15px;
    margin: 15px auto 20px auto;
    width: auto;
    height: 30px;
    text-align: center;
}
.teaser-slider .slick-dots{
    margin-top: 0;
}


.slick-dots li {
    margin: 5px;
    display: inline-block;
    width: 18px;
    height: 18px;
}
.slick-dots button {
    border-radius: 15px;
    padding: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #009bdc;
    color: transparent;
    background: #009bdc;
    transition: 500ms;
}
.slick-dots .slick-active button {
    background: none;
}
.slick-dots button:focus {
    outline: none;
}
@media screen and (min-width: 768px) {
    .portal-slider {
        padding-right: .5px;
        padding-left: .5px;
        padding-bottom: 1px;
    }
    .slick-next, .slick-prev {
        position: absolute;
        top: 45%;
        font-size: 70px;
        cursor: pointer;
    }
    .slick-next {
        right: 35px;
    }
    .slick-prev {
        left: 35px;
    }
    .slick-dots{
        display: none !important;
    }
    .portal-slider--claim .btn {
        padding: 10px 60px;
    }
    .portal-slider--claim h1 {
        font-size: 90px;
    }
    .portal-slider--claim h2 {
        font-size: 58px;
    }
}
@media screen and (min-width: 1200px) {
    .portal-slider--claim {
        top: 45%;
    }
}

.slick-slide + .slick-slide {
    display: block;
}


.portal-slider--item__img {
    position: relative;
}
.portal-slider--item__img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    background: linear-gradient(to bottom,  transparent 0%,#000000 100%);
    opacity: 0.5;
    transition: opacity 250ms ease-in-out;
    will-change: opacity;
}
.portal-slider--item__img:hover:after {
    opacity: 0;
}
@media screen and (min-width: 768px) {
    .portal-slider--item__img:after {
        opacity: 0;
    }
    .portal-slider:hover .portal-slider--item__img:after {
        opacity: 0.5;
    }
}