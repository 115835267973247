.navbar-search {
    position: fixed;
    width: 100%;
    height: 0;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    background: #f09600;
    z-index: 2;
    transition: 200ms ease-in;
    visibility: hidden;
}
.navbar-search.is-open {
    height: 126px;
    visibility: visible;
}
.navbar-search .form-group {
    width: 100%;
    display: none;
}
.navbar-search .form-control {
    background: none;
    border: none;
    border-radius: 0;
    width: 75%;
    margin-left: 5%;
    font-size: 30px;
    height: 60px;
    color: #fff;
    transition: all 400ms cubic-bezier(.64,.09,.08,1);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 96%, #fff 4%);
    background-position: -1000px 0;
    background-size: 100%;
    background-repeat: no-repeat;
    box-shadow: none;
    border-bottom: 1px solid #fff;
    margin-top: 25px;
}
.navbar-search .form-control:focus {
    box-shadow: none;
    background-position: 0 0;
}
.navbar-search ::-webkit-input-placeholder {
    color: #fff;
}
.navbar-search ::-moz-placeholder {
    color: #fff;
}
.navbar-search--list {
    color: #009bdc;
}
.open-search {
    z-index: 5;
    position: fixed;
    transition: 500ms cubic-bezier(0.65, 0.05, 0.36, 1);
    bottom: 80px;
    right: 60px;
    max-width: 60px;
    cursor: pointer;
    display: block;
}
.open-search .icon-search {
    background: #f09600;
    border-radius: 100%;
    padding: 18px;
    box-shadow: 0 2px 3px rgba(0,0,0,.1),0 2px 3px rgba(0,0,0,.2);
    position: absolute;
    left: 0;
}
.open-search.is-open {
    bottom: 100%;
    margin-bottom: -20px;
}
.open-search .icon-search:before {
    transition: 200ms ease-in;
    transform: rotate(360deg);
}
.open-search.is-open .icon-search:before {
    content: "\E014";
    color: #f09600;
    transition: 200ms ease-in;
    transform: rotate(0deg);
}
.affix .open-search .icon-search:before {
    transform: rotate(360deg);
}
.open-search.is-open .icon-search {
    background: #fff;
}
.navbar-search:after {
    content: '';
    position: fixed;
    background: #fff;
    height: 0;
    width: 100%;
    transition: 200ms ease-in;
}
.navbar-search.is-open:after {
    height: 100%;
    transform: translateY(35px);
}
.navbar-search.is-open .form-group {
    display: block;
}
@media screen and (min-width: 768px) {
    .navbar-search {
        position: absolute;
    }
    .navbar-search:after {
        content: none;
    }
    .open-search.is-open {
        transform: translateY(-90px);
        bottom: -10px;
        margin-bottom: 0;
    }
    .open-search{
        bottom: -10px;
        position: relative;
        left: 95%;
        transform: translateY(-36px);
    }
    .navbar-search .form-control {
        font-size: 50px;
        height: 100px;
        margin-top: 0;
    }
    .affix .open-search {
        transform: translateY(-36px);
    }
}

@media screen and (min-width: 1200px) {
    .open-search{
        bottom: 0;
        left: 92%;
    }
}

/*Typeahead*/
.twitter-typeahead {
    width: 75%;
    margin-left: 5%;
    position: static !important;
}
.tt-dropdown-menu {
    width: 100%;
    background: #fff;
    padding: 18px 30px;
}
.tt-suggestions {
    left: 5%;
    position: relative;
}
.tt-suggestion {
    padding: 20px 0;
    border-bottom: 1px solid #c3c3c5;
}
.tt-dataset-states {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    color: #009bdc;
}
.tt-suggestion p {
    margin: 0;
}