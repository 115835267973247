.parsley-errors-list {
    position: absolute;
    list-style: none;
    color: #b40a19;
    right: 10px;
    padding: 0;
}
.has-error ~ .form-control-line {
    background: #b40a19;
}
.has-error.form-control--special:focus~.form-control-label,
.has-error.form-control--special:valid~.form-control-label,
.has-error.js-datepicker~.form-control-label,
.has-error ~ .form-control-label, .is-not-empty.has-error ~ .form-control-label {
    color: #b40a19;
}
.form-control.has-error, .form-control.has-error:focus {
    border-bottom:1px solid #b40a19;
}
.form-control.select.has-error {
    border: 1px solid #b40a19;
}
.label__checkbox-fake.has-error .checkbox-fake--box {
    border-color: #b40a19;
    box-shadow: 0 0 5px red;
}