.teaser {
    box-shadow: 0 0 6px 0px #dbdbdb;
    position: relative;
}
.teaser__head {
    position: relative;
}
.teaser__head--text {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 20px;
}
.teaser__head .label-top-left {
    background: #009bdc;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    line-height: 14px;
    padding: 8px 12px;
}
.teaser__body {
    padding: 15px 20px;
    background: #fff;
    height: 200px;
    overflow: hidden;
    position: relative;
}
.teaser__body h2 {
    font-size: 20px;
    font-weight: 400;
    font-family: 'SourceSansPro-Semibold';
    margin-bottom: 10px;
}
.teaser__body--link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 20px 10px;
}
.teaser:hover .img-overlay {
    opacity: 0;
}
.teaser__head--text h2 {
    line-height: 24px;
}


.teaser-show-all {
    display: flex;
    align-items: center;
    justify-content: center;
}
.teaser-show-all:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: 250ms ease-in-out;
}
.teaser-show-all:hover:after {
    opacity: 0.2;
}
.teaser-show-all__content{
    padding: 15px;
}

.teaser__center {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 38% 50px;
    z-index: 1;
}
.teaser__center--text {
    line-height: 40px;
}
.teaser__center .icon{
    font-size: 60px;
}
@media screen and (min-width: 768px){
    .teaser__head--text {
        bottom: 10px;
    }
}
/*Image Teaser*/
.img-teaser {
    max-width: 200px;
    box-shadow: none;
}
.img-teaser .img-with-overlay {
    position: relative;
}
.img-teaser__lake {
    position: absolute;
    width: 90%;
    left: 8px;
    top: 15%;
    z-index: 1;
}
@media screen and (min-width: 768px){
    .img-teaser {
        max-width: 250px;
    }
    .teaser__body {
        height: 270px;
    }
    .img-teaser__lake {
        width: 100%;
        left: 0;
    }

    /*Service Teaser*/
    .service-teaser {
        max-width: 340px;
    }
}
@media screen and (min-width: 1200px) {
    .teaser__body {
        height: 215px;
    }
}

/*Pauschal Teaser*/
.pauschal-teaser {
    max-width: 285px;
}
.pauschal-teaser .teaser__body {
    height: 85px;
    flex-grow: 1;
}

@media screen and (min-width: 768px){
    .row--same-height .pauschal-teaser .teaser__body {
        height: 218px;
    }

}

@media screen and (min-width: 1200px){
    .row--same-height .pauschal-teaser .teaser__body {
        height: 172px;
    }

}

/*Presse Teaser*/
.presse-teaser .teaser__body {
    height: 130px;
}

/*Wide Teaser*/
.teaser-wide {
    max-width: 720px;
}
.teaser-wide .teaser__body{
    padding: 14px 20px 10px;
    height: auto;
}

@media screen and (max-width: 767px) {
    .teaser-slider {
        padding: 0 15px;
    }
}

.teaser-slider-show-all{
    width: 100%;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -5px;
}
.teaser-slider-show-all--pauschal-teaser{
    padding: 0;
    max-width: 285px;
}

.teaser-slider-show-all__content{
    font-size: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.teaser-slider-show-all__content>div{
    position: relative;
    top: -6px;
}
.teaser-slider-show-all__content>div .icon-arrow{
    margin-left: 10px;
    font-size: 30px;
    top: 8px;
    position: relative;
}

.teaser-slider .slick-next,
.teaser-slider .slick-prev {
    position: absolute;
    font-size: 30px;
    top: 50%;
    transform: translateY(-50%);
}
.teaser-slider .slick-next {
    right: 6px;
}
.teaser-slider .slick-prev {
    left: 6px;
    transform: translateY(-50%) rotate(180deg);
}
@media screen and (min-width: 768px) {
    .teaser-slider .slick-next {
        right: -15px;
    }
    .teaser-slider .slick-prev {
        left: -15px;
    }
}
@media screen and (min-width: 1050px) {
    .teaser-slider .slick-next,
    .teaser-slider .slick-prev {
        font-size: 40px;
    }
    .teaser-slider .slick-next {
        right: -40px;
    }
    .teaser-slider .slick-prev {
        left: -40px;
    }
}


/* Teaser Booking */
.teaser--box{
    max-width: 600px;
}
.teaser--box--blue {
    color: #00b1b1;
}

.teaser--box .teaser--box__body{
    overflow: visible;
    padding: 20px 0;
}
.teaser--box__body .teaser--box__body__icon{
    display: block;
    font-size: 30px;
}
.teaser--box__body .booking__text{
    max-width: 400px;
    margin: auto;
    padding: 17px 0;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    display: block;
}
.teaser--box__body .booking__link {
    font-size: 18px;
}
.teaser--box__body .icon-arrow,
.teaser--box__arrow{
    font-size: 16px;
    font-weight: 600;
    padding: 12px 17px;
    border-radius: 100%;
    background: #b40a19;
    color: #fff;
    display: inline-block;
    bottom: -21px;
    position: absolute;
    right: 50%;
    transform: translateX(21px);
    transition: 200ms ease-in-out;
    width: 40px;
    height: 40px;
}
.teaser--box--blue .teaser--box__body .icon-arrow {
    background: #00b1b1;
}
.teaser--box .teaser--box__arrow--primary {
    background: #009bdc;
}
@media screen and (min-width: 768px){
    .teaser--box:hover .icon-arrow,
    .teaser--box:hover .teaser--box__arrow{
        transform: translateX(33px);
    }
    .teaser--box__body .teaser--box__body__icon{
        font-size: 33px;
    }
    .teaser--box__body .booking__text{
        padding: 17px 0 26px 0;
        font-size: 30px;
    }
    .teaser--box__body .booking__link {
        font-size: 28px;
    }
    .teaser--box__body .icon-arrow,
    .teaser--box__arrow {
        font-size: 21px;
        transform: translateX(25px);
        padding: 15px 20px;
        bottom: -25px;
        width: 50px;
        height: 50px;
    }
}

@media screen and (min-width: 768px){
    .img-teaser--flex {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .img-teaser--flex__title {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
        justify-content: center;
    }
}
.teaser--box__title {
    font-size: 22px;
    line-height: calc(23/18);
    margin: 15px 0 0;
}
.tesaer--box-lg {
    font-size: 14px;
    line-height: calc(16/14);
}
.teaser--box-lg .teaser--box__body {
    padding: 30px 0 40px;
}