.checkbox-fake[type=checkbox] { display:none; }

.label__checkbox-fake {
    padding-left: 25px;
    cursor: pointer;
    position: relative;
    transform: translateZ(0);
}
.label__checkbox-fake .checkbox-fake--box, .label__checkbox-fake .checkbox-fake--check:before{
    transition-duration: 150ms;
}

.checkbox-fake--box     {
    position: absolute;
    left: 4px;
    top: 5px;
    border: 1px solid #c3c3c5;
    height: 15px;
    width: 15px;
    transition-delay: 100ms;
}


.checkbox-fake--check {
    left: 5px;
    top: 1px;
    position: absolute;
    font-size: 12px;
}
.checkbox-fake--check:before {
    font-family: iconfont;
    content: "\E013";
    position: absolute;
    color: #0f9d58;
    transform: rotate(45deg);
    transition-delay: 100ms;
    opacity: 0;
}

.checkbox-fake[type=checkbox]:checked ~ .checkbox-fake--box {
       opacity:0;
       transform:scale(0) rotate(-180deg);
}

.checkbox-fake[type=checkbox]:checked ~ .checkbox-fake--check:before {
    opacity:1;
    transform:scale(1) rotate(0deg);
}