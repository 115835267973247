.landingpage-top {
    position: relative;
    padding: 15px 0;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .landingpage-top {
        padding: 30px 0;
    }
}
.landingpage-top__backlink{
    position: absolute;
    left: 0;
    top: 15px;
    z-index: 1;
    font-size: 12px;
}
@media screen and (min-width: 768px) {
    .landingpage-top__backlink {
        top: 30px;
        font-size: 14px;
    }
}
.landingpage-top__backlink .landingpage-top__backlink__icon{
    display: inline-block;
    transform: rotate(180deg);
    margin-right: 7px;
    font-size: 8px;
}
.landingpage-top__backlink:hover {
    color: #fff;
    text-decoration: underline;
}
.landingpage-top__logo {
    z-index: 1;
    background: #fff;
    max-width: 70px;
}
@media screen and (min-width: 768px) {
    .landingpage-top__logo {
        background: transparent;
    }
}
@media screen and (min-width: 1200px) {
    .landingpage-top__logo {
        max-width: none;
    }
}