html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content*/
}

body {
    position: relative;
    color: #404040;
    line-height: 22px;
}
.container {
    max-width: 1310px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}
.container-narrow {
    max-width: 822px;
}
.row {
    margin-left: -10px;
    margin-right: -10px;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
}
.container>.navbar-header {
    margin-left: 0;
    margin-right: 0;
}
.content-block {
    margin-bottom: 30px;
    margin-top: 30px;
}
.content-block--max {
    max-width: 720px;
    width: 100%;
}
.content-block--max--booking-teaser{
    max-width: 600px;
    margin-bottom: 57px;
}
.container-block {
    padding-left: 15px;
    padding-right: 15px;
}
.page-wrapper {
    background: #ffffff;
    position: relative;
    z-index: 1;
}
@media screen and (min-width: 768px){
    .container-block {
        padding-left: 0;
        padding-right: 0;
    }
    .page-wrapper {
        background: #fafafa;
        padding-top: 126px;
    }
    .container {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    body {
        width: 982px;
    }
}

.page-wrapper.page-wrapper--landingpage {
    padding-top: 0;
}