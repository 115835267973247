.form-control{
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    border: 1px solid #c3c3c5;
    min-height: 40px;
}
.form-control:focus {
    box-shadow: none;
}
.form-control[readonly]{
    background: transparent;
}
.form-control.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
}
.form-control.select::-ms-expand {
    display: none;
}
.form-control.select ~ .icon-open{
    position: absolute;
    right: 20px;
    bottom: 13px;
    font-size: 12px;
    pointer-events: none;
}