.breadcrumb {
    background-color: transparent;
    font-weight: 400;
    font-family: 'SourceSansPro-Light';
    margin-bottom: 30px;
    position: relative;
    padding: 8px 0;
}
.breadcrumb>li {
    color: #fff;
    opacity: 0.5;
    padding-right: 0;
}
.breadcrumb>li:not(:last-child):after {
    content: ">";
    padding: 0 10px;
}
.breadcrumb>li+li:before {
    content: none;
    padding: 0;
}
.breadcrumb>.active {
    opacity: 1;
    color: #fff;
}
.breadcrumb a:hover {
    text-decoration: underline;
}
@media screen and (min-width: 768px){
    .breadcrumb {
        margin-bottom: 50px;
    }
    .breadcrumb {
        margin-bottom: 20px;
    }
}