.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FAFAFA;
}
.overlay-toggle {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #9B2689;
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: 24px;
    box-shadow: 0 2px 3px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2);
    pointer-events: initial;
}
@media screen and (min-width: 1200px) {
    .overlay-toggle {
        width: 60px;
        height: 60px;
    }
}
.overlay-toggle:hover {
    color: #fff;
}
.overlay-toggle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.overlay-toggle__badge + .overlay-toggle__icon {
    transform: translateX(-58%) translateY(-44%);
}
.overlay-toggle__badge {
    position: absolute;
    right: -9px;
    top: 0;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background: #404040;
    border: 1px solid #FFFFFF;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}