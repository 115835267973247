.weather-slide {
    background-size: cover;
    background-position: center;
    padding: 12px 12px 0;
    position: relative;
}
@media screen and (max-width: 767px) {
    .weather-slide {
        padding-left: 0;
        padding-right: 0;
    }
}
@media screen and (min-width: 768px){
    .weather-slide {
        padding: 26px 28px;
    }
}
.weather-slide:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, #0089d3 0%, #00a8e1 100%);
    opacity: .85;
}
.weather-slide__content {
    position: relative;
    z-index: 1;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    font-size: 16px;
}
@media screen and (min-width: 1080px){
    .weather-slide__content {
        font-size: 18px;
    }
}
@media screen and (min-width: 1200px){
    .weather-slide__content {
        font-size: 20px;
    }
}
@media screen and (max-width: 767px){
    .weather-slide__content {
        padding: 0 10px;
    }
    .weather-slide__content>.row>.col {
        margin-bottom: 15px;
    }
}
.weather-slide__content__hl{
    font-size: 25px;
}
@media screen and (min-width: 786px) {
    .weather-slide__content__data{
        padding: 0 30px;
    }
}
@media screen and (min-width: 1200px) {
    .weather-slide__content__data{
        padding: 0 80px 0 30px;
    }
    .weather-slide__content__data + .weather-slide__content__data {
        margin-top: 12px;
    }
}
.weather-slide__content__data + .weather-slide__content__data {
    margin-top: 7px;
}
.weather-slide__content__icon {
    font-size: 70px;
}
@media screen and (min-width: 1200px) {
    .weather-slide__content__icon {
        font-size: 80px;
    }
}
@media screen and (min-width: 768px) {
    .weather-slide__content__col{
        position: relative;
        padding-left: 15px;
    }
}
@media screen and (min-width: 1200px) {
    .weather-slide__content__col{
        padding-left: 30px;
    }
}
@media screen and (min-width: 786px){
    .weather-slide__content__col:after {
        content: '';
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: #fff;
    }
}
.weather-slide__content__col__icon{
    font-size: 20px;
    vertical-align: -.15em;
    margin-left: 5px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .weather-slide .temperature__value {
        font-size: 60px;
        padding-right: 32px;
    }
    .weather-slide .temperature--sm .temperature__value__small {
        font-size: 18px;
    }
}