.author {
    position: relative;
    margin-bottom: 30px;
    padding-top: 15px;
}
@media screen and (min-width: 768px) {
    .author {
        padding-top: 0;
        top: -34px;
    }
}
.blog-slide .author {
    top: -40px;
    padding-top: 0;
}
.author__info {
    color: #9a9a9a;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 25px;
}
.blog-slide .author__info {
    margin-top: 0;
}
.author__statement {
    color: #666666;
    font-size: 26px;
    position: relative;
    display: inline-block;
    line-height:1.2;
}
@media screen and (min-width: 768px) {
    .author__statement {
        font-size: 42px;
        line-height: calc(60/42);
    }
}
.author__statement__quote {
    display: inline-block;
    font-size: 10px;
    vertical-align: -0.45em;
    margin-right: -2px;
}
@media screen and (min-width: 768px) {
    .author__statement__quote {
        font-size: 18px;
        vertical-align: -0.85em;
        margin-right: -5px;
    }
}
.author__statement__quote--end {
    margin-right:0;
    vertical-align: 1.2em;
}
@media screen and (min-width: 768px) {
    .author__statement__quote--end {
        margin-right:0;
        vertical-align: 1.4em;
    }
}
.author__bottom {
    margin-top: 15px;
    border-top: 2px solid rgba(255,255,255,.5);
    font-size: 16px;
    padding: 15px 0 5px;
}
@media screen and (min-width: 768px) {
    .author__bottom {
        margin-top: 28px;
        padding: 32px 0 20px;
    }
}