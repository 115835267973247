.hero-img {
    position: relative;
}
.hero-img--with-overlay:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%); 
}
.hero-img__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 1;
    padding: 20px 0;
}
@media screen and (min-width: 768px) {
    .hero-img__content {
        padding: 100px 0;
    }
}
.hero-img__content__hl {
    font-size: 24px;
}
.hero-img__content__hl--lg {
    font-size: 31px;
}
@media screen and (min-width: 768px) {
    .hero-img__content__hl {
        font-size: 50px;
    }
    .hero-img__content__hl--lg {
        font-size: 60px;
    }
}
@media screen and (min-width: 1200px) {
    .hero-img__content__hl {
        font-size: 70px;
    }
    .hero-img__content__hl--lg {
        font-size: 90px;
    }
}
.hero-img__backlink{
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 1;
    font-size: 12px;
}
@media screen and (min-width: 768px) {
    .hero-img__backlink {
        top: 30px;
        left: 40px;
        font-size: 14px;
    }
}
.hero-img__backlink .hero-img__backlink__icon{
    display: inline-block;
    transform: rotate(180deg);
    margin-right: 7px;
    font-size: 8px;
}
.hero-img__backlink:hover {
    color: #fff;
    text-decoration: underline;
}
.hero-img__logo {
    position: absolute;
    right:10px;
    top: 10px;
    z-index: 1;
    background: #fff;
    max-width: 100px;
}
@media screen and (min-width: 768px) {
    .hero-img__logo {
        right: auto;
        left:50%;
        transform: translateX(-50%);
        top: 30px;
        background: transparent;
    }
}
@media screen and (min-width: 1200px) {
    .hero-img__logo {
        max-width: none;
    }
}
@media screen and (min-width: 768px) {
    .hero-img .navbar-search {
        top: auto;
        bottom: 0;
    }
    .hero-img .open-search.is-open {
        transform: translateY(-100px);
    }
}

.hero-img__badge {
    z-index: 1;
    max-width: 80px;
    max-height: 80px;
    margin: 0 auto -20px;
    position: relative;
}

@media screen and (min-width: 768px) {
    .hero-img__badge {
        margin: 0;
        position: absolute;
        top: 20px;
        right: 20px;
        max-width: 120px;
        max-height: 120px;
    }
}