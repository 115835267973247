.google-map {
    position: relative;
}
@media screen and (min-width: 768px) {
    .google-map {
        min-height: 180px;
    }
}
.google-map__controls {
    position: absolute;
    right: 10px;
    top: 10px;
}
.google-map__controls .google-map__control {
    width: 35px;
    height: 35px;
    padding: 0;
    margin-bottom: 2px;
    transition: 200ms;
    overflow: hidden;
}
.google-map__control:active, .google-map__control:focus, .google-map__control:hover {
    color: #0082a5;
}
.google-map__control .icon {
    position: relative;
    top: 10px;
    right: 0;
}
.infoBox .teaser__body.teaser__body--autoHeight {
    height: auto;
    padding-bottom:10px;
}

.routeplanner {
    width: 100%;
    height: 630px;
    margin-bottom: 20px;
}

#directions table{
    width: 100%;
}

.anreisebtn.btn {
    padding: 9px 25px;
    background-color: #009bdc;
    border-color: #009bdc;
}

.anreisebtn:hover{
    background-color: #0083d0;
    border-color: #0083d0;
}


@media screen and (min-width: 768px){



    .google-map__controls {
        right: 50px;
        top: 50px;
    }
    .google-map__controls .google-map__control {
        width: 50px;
        height: 50px;
    }
    .google-map__control .icon {
        top: 18px;
    }
    .google-map__infobox {
        padding: 15px;
    }
}
.infoBox>img{
    z-index: 5;
    position: absolute !important;
    right: 0;
    top: 0;
}

@media screen and (max-width: 768px){
    .routeplanner {
        height: 330px;
    }
}

/* scrolling map */
.scrolling-map {
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
}
.scrolling-map__pois__teaser {
    padding: 30px 0;
}
.scrolling-map__map {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: 100vh;
}
.scrolling-map__pois {
    padding: 20px 15px;
    z-index: 1;
    position: relative;
}
@media screen and (min-width: 768px) {
    .scrolling-map__pois {
        padding: 30px;
    }
}
@media screen and (min-width: 768px){
    .google-map.is-fixed {
        position: fixed;
        top: 66px;
        bottom: 0;
        height: auto;
        width: 58.33333333%;
        max-width: 1120px;
    }
    .google-map.is-bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
@media screen and (min-width: 992px){
    .google-map.is-fixed {
        top: 133px;
    }
}

@media screen and (max-width: 767px) {
    .scrolling-map .google-map {
        position: fixed;
        top: 42px;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 6;
    }
    .scrolling-map .is-open.google-map {
        display: block;
    }
}

.google-map__btn {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}
.google-map__btn .btn {
    padding: 10px 20px;
}
@media screen and (min-width: 768px) {
    .google-map__btn .btn {
        padding: 15px 65px;
    }
}

/* map overlay */
.google-map-embed {
    position: relative;
    box-shadow: 0 0 6px 0 #dbdbdb;
}
.google-map-embed__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,.87);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
@media screen and (min-width: 1200px) {
    .google-map-embed__overlay {
        padding: 30px;
    }
}
@media screen and (min-width: 1200px) {
    .google-map-embed__overlay {
        padding: 30px 100px;
    }
}
.google-map-embed__overlay__title {
    font-size: 28px;
    line-height: calc(55/50);
    margin-bottom: 10px;
}
@media screen and (min-width: 768px){
    .google-map-embed__overlay__title {
        font-size: 50px;
        margin-bottom: 15px;
    }
}
.google-map-embed__overlay__subtitle {
    font-size: 18px;
    margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
    .google-map-embed__overlay__subtitle {
        font-size: 28px;
        margin-bottom: 40px;
    }
}
.is-dynamic-map .google-map-embed {
    display: none;
}
.google-map-dynamic {
    display: none;
}
.is-dynamic-map .google-map-dynamic {
    display: block;
}