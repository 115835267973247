.picture-grid .blue-box{
    background: #00b9e8;
    background: -moz-linear-gradient(top,  #00b9e8 0%, #009bdc 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#00b9e8), color-stop(100%,#009bdc));
    background: -webkit-linear-gradient(top,  #00b9e8 0%,#009bdc 100%);
    background: -o-linear-gradient(top,  #00b9e8 0%,#009bdc 100%);
    background: -ms-linear-gradient(top,  #00b9e8 0%,#009bdc 100%);
    background: linear-gradient(to bottom,  #00b9e8 0%,#009bdc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b9e8', endColorstr='#009bdc',GradientType=0 );

}
.picture-text {
    position: absolute;
    bottom: 0;
    margin: 0 15px;
    z-index: 1;
}
.picture-text .mb10 {
    margin-bottom: 5px;
}
.section-img .picture-text a {
    height: 55px;
}
.picture-text-fixed {
    position: absolute;
    bottom: 0;
    padding: 10px 25px;
    width: 100%;
}
.picture-grid__content ul {
    list-style: none;
    padding: 0 25px;
}
.picture-grid__content li {
    padding: 15px 10px 15px 0;
    border-bottom: 1px solid #75ccf5;
    font-weight: 400;
    font-family: 'SourceSansPro-Semibold';
    transition: 200ms ease-in;
}
.picture-grid__content li:hover {
    padding-right: 0;
}
.picture-grid__content a:last-child li{
    border-bottom: 0;
}
.picture-grid {
    padding-bottom: 1px;
    overflow: hidden;
}
.picture-grid-text-only {
    display: table;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 21% 0;
}
.picture-grid-text-only h1 {
    display: table-cell;
    vertical-align: middle;
    font-weight: 400;
    font-family: 'SourceSansPro-Light';
    font-size: 45px;
}
@media screen and (min-width: 768px){
    .picture-text {
        position: relative;
        bottom: 80px;
        margin: 0 30px;
    }
    .section-img .picture-text {
        bottom: 100px;
    }
    .main-nav__dropdown .picture-text{
        top: 80px;
    }
    .picture-text .mb10 {
        margin-bottom: 10px;
    }
    .picture-grid__content a:last-child li{
        border-bottom: 1px solid #75ccf5;
    }
    .picture-grid__content li {
        padding: 10px 10px 10px 0;
    }
}
@media screen and (min-width: 1400px) {
    .main-nav__dropdown .picture-text{
        top: 105px;
    }
    .picture-grid__content li {
        padding: 15px 15px 15px 0;
    }
    .picture-grid__content ul {
        padding: 0 40px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1199px){
    .picture-grid__content ul {
        padding: 0 15px;
    }
}