.poi-teaser {
    height: 100%;
    background: #fff;
    transition: backgournd 250ms ease-in-out, color 250ms ease-in-out;
    box-shadow: 0 0 6px 0 #dbdbdb;
    position: relative;
}
.poi-teaser + .poi-teaser {
    margin-top: 20px;
}
.poi-teaser__head {
    position: relative;
}
.poi-teaser__body {
    padding: 15px 20px 60px;
    overflow: hidden;
    position: relative;
    flex-grow: 1;
}
.poi-teaser__body__title {
    font-size: 20px;
    font-family: SourceSansPro-Semibold;
    font-weight: 400;
    margin-bottom: 10px;
}
.poi-teaser__body__bottom{
    color: #009BDC;
    transition: color 250ms ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 20px 10px;
}
.poi-teaser__body__bottom__show-map > .icon {
    vertical-align: -.15em;
    font-size: 18px;
    margin-right: 5px;
}
.is-active.poi-teaser,
.is-active .poi-teaser__body__bottom{
    color: #fff;
}
.is-active.poi-teaser--poi,
.is-active.poi-teaser--standard{
    background: #009BDC;
}
.is-active.poi-teaser--aktiv{
    background: #0082A5;
}
.is-active.poi-teaser--bek{
    background: #00A5A5;
}
.is-active.poi-teaser--genuss{
    background: #73BE8C;
}
.is-active.poi-teaser--gesundheit{
    background: #69A0BE;
}
.is-active.poi-teaser--kultur{
    background: #7D82B9;
}
.is-active.poi-teaser--natur{
    background: #00A078;
}
