@font-face {
    font-family: "iconfont";
    src: url('/static/icons/font/iconfont.eot?#iefix') format('eot'),
        url('/static/icons/font/iconfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.icon {
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.icon-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.icon-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
    filter: none;
}

.icon-anreise:before { content: "\E001" }
    .icon-arrow:before { content: "\E002" }
    .icon-googleplus:before { content: "\E003" }
    .icon-kalender:before { content: "\E004" }
    .icon-facebook:before { content: "\E005" }
    .icon-mail:before { content: "\E006" }
    .icon-prospekt:before { content: "\E007" }
    .icon-spring:before { content: "\E008" }
    .icon-star:before { content: "\E009" }
    .icon-summer:before { content: "\E00A" }
    .icon-list-item:before { content: "\E00B" }
    .icon-teaser-ansicht:before { content: "\E00C" }
    .icon-website:before { content: "\E00D" }
    .icon-mobile_menu:before { content: "\E00E" }
    .icon-winter:before { content: "\E00F" }
    .icon-fax:before { content: "\E010" }
    .icon-autumn:before { content: "\E011" }
    .icon-bek_card:before { content: "\E012" }
    .icon-check:before { content: "\E013" }
    .icon-close:before { content: "\E014" }
    .icon-download:before { content: "\E015" }
    .icon-expanding:before { content: "\E016" }
    .icon-open:before { content: "\E017" }
    .icon-phone:before { content: "\E018" }
    .icon-play-small:before { content: "\E019" }
    .icon-sound-off:before { content: "\E01A" }
    .icon-sound-on:before { content: "\E01B" }
    .icon-zoom-in:before { content: "\E01C" }
    .icon-zoom-out:before { content: "\E01D" }
    .icon-karten-ansicht:before { content: "\E01E" }
    .icon-pause:before { content: "\E01F" }
    .icon-play:before { content: "\E020" }
    .icon-poi_marker:before { content: "\E021" }
    .icon-search:before { content: "\E022" }
    .icon-clock:before { content: "\E023" }
    .icon-card-klein:before { content: "\E024" }
    .icon-dog:before { content: "\E025" }
    .icon-print:before { content: "\E026" }
    .icon-share:before { content: "\E027" }
    .icon-elements:before { content: "\E028" }
    .icon-BEKallgemein:before { content: "\E029" }
    .icon-baederundvitalerlebnisse:before { content: "\E02A" }
    .icon-bergbahnerlebnisse:before { content: "\E02B" }
    .icon-burgundschlosserlebnisse:before { content: "\E02C" }
    .icon-exklusiverlebnisse:before { content: "\E02D" }
    .icon-hundeerlaubt:before { content: "\E02E" }
    .icon-museenundkulturerlebnisse:before { content: "\E02F" }
    .icon-naturundfreizeiterlebnisse:before { content: "\E030" }
    .icon-schiffserlebnisse:before { content: "\E031" }
    .icon-stadterlebnisse:before { content: "\E032" }
    .icon-verkaufsstelle:before { content: "\E033" }
    .icon-twitter:before { content: "\E034" }
    .icon-quote:before { content: "\E035" }
    .icon-360:before { content: "\E036" }
    .icon-bed:before { content: "\E037" }
    .icon-audio-guide:before { content: "\E038" }
    .icon-barrierefrei:before { content: "\E039" }
    .icon-flower:before { content: "\E03A" }
    .icon-bus:before { content: "\E03B" }
    .icon-rad:before { content: "\E03C" }
    .icon-schueler:before { content: "\E03D" }
    .icon-bier:before { content: "\E03E" }
    .icon-fisch:before { content: "\E03F" }
    .icon-familienfreundlich:before { content: "\E040" }
    .icon-gastronomieangebot:before { content: "\E041" }
    .icon-info-tafel:before { content: "\E042" }
    .icon-naturschutzgebiet:before { content: "\E043" }
    .icon-unesco-fundstelle:before { content: "\E044" }
    .icon-wetterunabhaengig:before { content: "\E045" }
    .icon-instagram:before { content: "\E046" }
    .icon-map:before { content: "\E047" }
    .icon-megaphone:before { content: "\E048" }
    .icon-info:before { content: "\E049" }
    .icon-kaese:before { content: "\E04A" }
    .icon-wein:before { content: "\E04C" }
    .icon-warning:before { content: "\E050" }
    .icon-express-check-in:before { content: "\E051" }
    .icon-veggies:before { content: "\E04B" }
    