.asset-video {
    height: 600px;
    overflow: visible;
}
.embed-responsive-item.video-js {
    width: 100% !important;
    height: 100% !important;
}
.vjs-default-skin.vjs-default-skin .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-top: -52px;
    margin-left: -52px;
    width: 100px;
    height: 100px;
    border: 0;
    font-size: 10em;
    background: none;
}
.asset-video .vjs-default-skin:hover .vjs-big-play-button {
    background: none;
    box-shadow: 0 0 2em #fff;
}
.vjs-default-skin.vjs-default-skin .vjs-big-play-button:before {
    text-shadow: none;
    line-height: 100px;
    content: "\E020";
    font-family: 'iconfont';
    color: #fff;
}
.vjs-default-skin.vjs-default-skin .vjs-control {
    color: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-control:focus:before,
.vjs-default-skin.vjs-default-skin .vjs-control:hover:before {
    text-shadow: none;
    opacity: .9;
}
.vjs-default-skin.vjs-default-skin .vjs-play-control:before {
    content: "\E019";
    font-family: iconfont;
}
.vjs-default-skin.vjs-default-skin.vjs-playing .vjs-play-control:before {
    content: "\E01F";
    font-family: iconfont;
}
.vjs-default-skin.vjs-default-skin .vjs-mute-control:before, .vjs-default-skin.vjs-default-skin .vjs-volume-menu-button:before {
    content: "\E01A" !important;
    font-family: iconfont;
}
.vjs-default-skin.vjs-default-skin .vjs-mute-control.vjs-vol-0:before, .vjs-default-skin.vjs-default-skin .vjs-volume-menu-button.vjs-vol-0:before {
    content: "\E01B" !important;
    font-family: iconfont;
}
.vjs-default-skin.vjs-default-skin .vjs-fullscreen-control:before {
    content: "\E016";
    font-family: iconfont;
}
.vjs-default-skin.vjs-default-skin.vjs-fullscreen .vjs-fullscreen-control:before {
    content: "\E016";
    font-family: iconfont;
}
@media screen and (max-width: 767px) {
    .vjs-default-skin .vjs-big-play-button.vjs-big-play-button:before {
        line-height: 1.3;
        left: 5px;
    }
    .asset-video {
        height: 250px;
    }
}