.weather-block + .weather-block{
    margin-top: 20px;
}
.weather-block__img {
    position: relative;
}
.weather-block__img:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 396px;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}
.weather-block__img__text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: 18px;
    padding: 8px 15px;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
@media screen and (min-width: 768px){
    .weather-block__img__text {
        font-size: 20px;
        padding: 15px 30px;
    }
}
.weather-block__img__detail {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 16px;
    z-index: 1;
}
@media screen and (min-width: 768px){
    .weather-block__img__detail {
        right: 30px;
        bottom: 25px;
    }
}
.weather-block__img__detail > .icon {
    font-size: 10px;
    margin-left: 6px;
    display: inline-block;
    transition: transform 250ms ease-in-out;
}
.weather-block__img__detail:hover {
    color: #fff;
}
.weather-block__img__detail:hover > .icon {
    transform: translateX(5px);
}
.weather-block__detail {
    padding: 25px 15px 10px;
}
@media screen and (min-width: 768px){
    .weather-block__detail {
        padding: 50px 28px 20px;
    }
    .weather-block-sm .weather-block__detail {
        padding: 30px;
    }
}
.weather-block__detail__icon {
    font-size: 80px;
}
@media screen and (min-width: 768px) {
    .weather-block__detail__icon {
        font-size: 130px;
    }
    .weather-block-sm .weather-block__detail__icon {
        font-size: 80px;
    }
}
.weather-block__detail__hl{
    font-size: 24px;
    margin-top: 20px;
}
@media screen and (min-width: 768px){
    .weather-block__detail__hl{
        font-size: 30px;
    }
    .weather-block-sm .weather-block__detail__hl {
        font-size: 20px;
        margin-bottom: 4px;
    }
}
.weather-block__detail__data {
    font-size: 16px;
}
.weather-block__detail__data + .weather-block__detail__data {
    margin-top: 5px;
}
@media screen and (min-width: 768px){
    .weather-block__detail__data {
        font-size: 20px;
    }
    .weather-block__detail__data + .weather-block__detail__data {
        margin-top: 22px;
    }
}

@media screen and (max-width: 767px){
    .weather-block-sm {
        margin-bottom: 20px;
        text-align: center;
    }
    .weather-block-sm .weather-block__detail {
        padding: 15px;
    }
}


/* ************     WEATHER MAP     ************ */
.bg-weather-map{
    position: relative;
    background-size: cover;
    background-position: center;
}
.bg-weather-map:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg,#00a8e1 0,#0089d3 100%);
    opacity: 0.85;
}
.bg-weather-map:after{
    content: '';
    position: absolute;
    height: 270px;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(180deg, #00a8e1 0%, rgba(0, 167, 225, 0) 100%);

}
.weather-map{
    position: relative;
    z-index: 1;
    width: 795px;
    margin: 30px auto 50px auto;
}
.weather-map-info {
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    color: #fff;
    font-size: 20px;
    z-index: 1;
}
.weather-map-info .col:nth-child(2) .weather-map-info__block{
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    border-right: 1px solid rgba(255, 255, 255, 0.6);
}
.weather-map-info .col:first-child .weather-map-info__block {
    padding-left: 0;
}
.weather-map-info .col:last-child .weather-map-info__block {
    padding-right: 0;
}
.weather-map-info__block{
    padding: 25px 50px;
}

.weather-map-info__block .weather-map-info__block__hl{
    font-family: 'SourceSansPro-Regular';
    font-size: 25px;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.weather-map-info__block .weather-map-info__block--special-text{
    font-size: 18px;
    line-height: calc(25/18);
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.temperature-label{
    /*margin-bottom: 5px;*/
}
.temperature__value{
    padding-top: 8px;
    padding-bottom: 3px;
}
.btn.temperature__detail-link{
    padding-left: 0;
    font-size: 16px;
}

.btn.temperature__detail-link > .icon-arrow{
    font-size: 10px;
    top: 13px;
}

.weather-map svg g:not(.map__no-hover):hover>path, .weather-map svg g:not(.map__no-hover):focus>path{
    cursor: pointer;
    fill: #e6e6e6;
}
.weather-map svg text{
    cursor: pointer;
}

.weather-map svg g.info-active{
    fill: #e6e6e6;
}

.weather-map-data__box{
    position: absolute;
    color: #fff;
    background-color: #f09600;
    padding: 28px 28px 22px 28px;
    cursor: default;
    pointer-events: none;
    opacity: 0;
    transition: all 250ms ease;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.weather-map-data__box.active{
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
}
.weather-map-data__box:after{
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    margin: auto;
    border-top: 14px solid #f09600;
    border-bottom: 5px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

}
.weather-map-data__box .weather-map-data__box__close{
    position: absolute;
    cursor: pointer;
    top: -20px;
    padding: 12px;
    right: 50%;
    transform: translateX(20px);
    border-radius: 100%;
    z-index: 1;
    background: #fff;
    color: #f09600;
    font-size: 16px;
    box-shadow: 0 2px 3px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2);
}
.weather-map-data__box__label{
    font-size: 30px;
}
.weather-map-data__box__label .weather-icon{
    padding-left: 5px;
    font-size: 22px;
}

.weather-map-data__box .temperature__value{
    padding-right: 0;
}


.weather-map-info__block .temperature__value,
.weather-map-data__box .temperature__value{
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}


@media screen and (max-width: 767px){
    .weather-map-info{
        border-top: 0;
    }

    .weather-map-info__block{
        padding: 25px 0;

    }
    .weather-map-info .col:nth-child(2) .weather-map-info__block{
        border: none;
    }
}