.blog-teaser {
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}
.blog-teaser__body {
    padding: 35px 35px 18px;
    flex-grow: 1;
}
.blog-teaser__body__hl {
    font-family: 'SourceSansPro-Semibold', sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height:1.2;
    margin-bottom: 14px;
}
.blog-teaser__bottom {
    width: 70%;
    padding: 18px 35px;
    border-top:1px solid #dedede;
}
.blog-teaser__head {
    position: relative;
}
.blog-teaser__head__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.blog-teaser .blog-teaser__head__overlay__icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 80px;
}

.blog-teaser__head__badge {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    max-width: 60px;
    max-height: 60px;
}

@media screen and (min-width: 768px) {
    .blog-teaser__head__badge {
        max-width: 80px;
        max-height: 80px;
    }
}