footer {
    color: #fff;
}
footer .bg-primary {
    padding: 25px;
}

@media screen and (min-width: 768px) and (max-width: 1199px){
    .footer__logo {
        max-width: 170px;
    }
}

@media screen and (min-width: 768px){
    footer .bg-primary {
        padding: 55px;
    }
}

footer .bg-secondary {
    padding: 15px;
}
.social-icons li {
    font-size: 55px;
    float: left;
    margin-right: 2px;
}
.social-icons .icon {
    display: block;
    height: 39px;
    line-height: 39px;
}
.social-icons a:hover>.icon {
    color: #0088c2;
    background-color: #fff;
}
.footer__logo {
    max-width: 170px;
}
.footer__logo>img{
    display: inline-block;
    width: 100%;
}

.footer__hl {
    font-size: 20px;
}
.footer__center-logo {
    margin: 25px 0;
}
@media screen and (min-width: 768px) {
    .footer__center-logo {
        margin: 45px 0;
    }
}
footer ul {
    line-height: 24px;
}
footer a:hover {
    text-decoration: underline;
}
footer .social-icons a:hover{
    text-decoration: none;
}
@media screen and (min-width: 1200px){
    .social-icons li {
        font-size: 60px;
    }

    .footer__logo {
        max-width: 186px;
    }
}