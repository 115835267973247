.btn {
    border-radius: 0px;
    padding: 5px 18px;
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    position: relative;
    font-size: 14px;
}
.btn--icon-only.btn--icon-only {
    padding: 10px;
}
.btn>.icon-arrow {
    position: absolute;
    font-weight: 600;
    right: 5px;
    top: 9px;
    transition: 200ms ease-in-out;
}
.btn-with-icon {
    padding: 6px 20px 6px 10px;
}
.btn-full-width {
    width: 100%;
}
@media screen and (min-width: 768px){
    .btn {
        padding: 6px 25px;
    }
    .btn-with-icon {
        padding: 6px 30px 6px 15px;
    }
    .btn-lg {
        padding: 10px 40px;
    }
    .btn-lg .icon {
        font-size: 12px;
        top: 13px;
        right: 22px;
    }
    .btn>.icon-arrow {
        right: 15px;
    }
    .btn-with-icon:hover .icon-arrow {
        /*right: 8px;*/
        transform: translateX(8px);
    }
}
.btn.btn-xl {
    padding: 10px 20px;
}
@media screen and (min-width: 768px) {
    .btn.btn-xl {
        padding: 15px 65px;
    }
}
.btn:hover, .btn:active, .btn:focus {
    color: #fff;
    box-shadow: none;
}
.btn.active {
    box-shadow: none;
}
.btn-group {
    margin-bottom: 0;
}
.btn-group .btn {
    margin-right: 2px;
}
.btn-transparent--white {
    border: 1px solid #fff;
}
.btn-transparent--purple {
    border: 1px solid #910f7d;
    color: #910f7d;
}
.btn-transparent--purple:focus, .btn-transparent--purple:hover {
    background: #910f7d;
}
.btn-transparent--purple .icon-arrow {
    transition: none;
}
.btn-transparent--purple.btn-transparent--purple:hover .icon-arrow{
    transform: translateX(0);
}
.btn-transparent--red {
    border: 1px solid #b40a19;
    color: #b40a19;
}
.btn-transparent--red:focus, .btn-transparent--red:hover {
    color: #b40a19;
}
.btn-transparent--blue{
    border: 1px solid #009bdc;
    color: #009bdc;
}
.btn-transparent--blue:focus, .btn-transparent--blue:hover{
    background: #009bdc;
    /*color: #fff;*/
}


.btn-xs--left {
    position: absolute;
    left: 0;
    bottom: 0;
}
.btn-xs--right {
    position: absolute;
    right: 0;
    bottom: 0;
}

/*Green Button*/
.btn-green {
    background: #348e4d;
}
.btn-green:hover {
    background: color(#348e4d b(+10%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}
/*Green Button light*/
.btn-green-light {
    background: #73be8c;
}
.btn-green-light:hover {
    background: color(#73be8c b(+10%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}
/*Green Button dark*/
.btn-green-dark {
    background: #00a078;
}
.btn-green-dark:hover {
    background: color(#00a078 b(+10%));
}
/*Purple Button*/
.btn-purple {
    background: #910f7d;
}
.btn-purple:hover {
    background: color(#910f7d b(+10%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}
/*Blue Button*/
.btn-blue {
    background: #009bdc;
}
.btn-blue:hover {
    background: color(#009bdc b(+10%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}
/*Red Button*/
.btn-red {
    background: #b40a19;
}
.btn-red:hover {
    background: color(#b40a19 b(+10%)); /*pleeease darker function http://dev.w3.org/csswg/css-color-4/#modifying-colors*/
}
/*Grey Button with Red Text Color*/
.btn-booking{
    color: #b40a19;
    background-color: #f0f0f0;
}
.btn-booking:hover{
    color: #b40a19;
    background: color(#f0f0f0 b(+10%));
}

.btn-grey-blue{
    background: #85aab3;
}
.btn-grey-blue:focus,
.btn-grey-blue:hover {
    background: #7eaeba;
}

.btn-no-styling,
.btn.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    font-size: inherit;
}

.btn .btn__icon {
    position: relative;
    top: 0;
    right: 0;
    vertical-align: -.2em;
    font-size: 16px;
    margin-right: -10px;
    margin-left: 10px;
}
@media screen and (min-width: 768px) {
    .btn .btn__icon {
        top: 0;
        right: 0;
        font-size: 16px;
        margin-right: -25px;
    }
}