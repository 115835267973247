.interactive-map {
    width: 100%;
    max-width: 380px;
}
.interactive-map__svg {
    max-width: 100%;
    height: auto;
}

.interactive-map .is-active > path {
    fill: #78C6E6;
}
.interactive-map-links a.is-active {
    color: #009BDC;
}