/* row--same-height */
.row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.row--same-height,
.row--same-height.slick-slider .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    /*align-items: stretch;*/
    /*justify-content: space-between;*/
    /*overflow: hidden;*/
}
.row--same-height {
    flex-wrap: wrap;
}
.row--same-height--one-row {
    flex-wrap: inherit;
}
.row--same-height.slick-slider {
    display: block;
}
.row--same-height:before,
.row--same-height:after {
    display: none;
}
.row--same-height > .col,
.row--same-height.slick-slider .col {
    display: flex;
    flex-direction: column;
    height: auto;
}

@media screen and (min-width: 768px) {
    .row--inline-block .teaser {
        width: 100%;
    }
    .row--inline-block .teaser,
    .row--same-height-sm .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--inline-block,
    .row--inline-block.slick-slider .slick-track,
    .row--same-height-sm,
    .row--same-height-sm.slick-slider .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /*align-items: stretch;*/
        /*justify-content: space-between;*/
        /*overflow: hidden;*/
    }
    .row--inline-block.slick-slider,
    .row--same-height-sm.slick-slider {
        display: block;
    }
    .row--inline-block:before,
    .row--inline-block:after,
    .row--same-height-sm:before,
    .row--same-height-sm:after {
        display: none;
    }
    .row--inline-block > .center-col,
    .row--inline-block.slick-slider .center-col,
    .row--same-height-sm > .col,
    .row--same-height-sm.slick-slider .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}
.row--inline-block {
    justify-content: center;
}

/*vertical gutter*/
.row--vertical-gutter-36 > .col {
    margin-top: 36px;
}

.row--vertical-gutter-10 {
    margin-top: -10px;
}
.row--vertical-gutter-10 > .col {
    margin-top: 10px;
}
.row--vertical-gutter-20 {
    margin-top: -20px;
}
.row--vertical-gutter-20 > .col {
    margin-top: 20px;
}

.row--align-center {
    display: flex;
    align-items: center;
}