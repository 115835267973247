a {
    color: inherit;
    cursor: pointer;
}
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
address {
    margin-bottom: 0;
}
textarea {
    resize: vertical;
}
label {
    font-weight: inherit;
}


#pc-cookie-notice {
    background: #c3c3c5!important;
    color: #444!important;
    font-family: SourceSansPro-Regular,sans-serif!important;
    font-size: 14px!important;
}

#pc-cookie-notice #pc-button button {
    background: #ebebeb!important;
}
