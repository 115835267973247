.filterlist__item {
    border-bottom: 1px solid #c3c3c5;
}
.filterlist__item.is-active .filterlist__head{
    padding-left: 25px;
}
.filterlist__item.is-active .filterlist__head:before {
    content: "\E013";
    font-family: iconfont;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    color: #009bdc;
    font-size: 10px;
}
.filterlist__head {
    position: relative;
    overflow: hidden;
    min-height: 45px;
}
.filterlist__head .ripple-circle {
    position: absolute;
}
.filterlist__head h3{
    margin-bottom: 0;
}
.filterlist__head a {
    padding: 15px 20px 15px 0;
    width: 100%;
}
.filterlist__head .label__checkbox-fake {
    margin-top: 10px;
}
.filterlist__head a.collapsed .icon:before, .filterlist__body a.collapsed .icon:before{
    content: "\E017";
}
.filterlist__body a {
    padding: 5px 20px 5px 0;
    width: 100%;
}
.filterlist__result, .filterteaser__result {
    padding: 10px 0;
}
.filterlist__result .row {
    height: 60px;
}
.filterlist__result .show-in-map {
    padding-right: 5px;
    border-right: 1px solid #009bdc;
    cursor: pointer;
}
.filterlist__result .show-detail {
    padding-left: 5px;
}
.sortbySelect {
    background: none;
    border-radius: 0;
    width: auto;
    display: inline-block;
    margin-left: 20px;
}

@media screen and (min-width: 768px){
    .filterlist__result .poi__circle {
        float: right;
    }
    .filterlist__result, .filterteaser__result {
        padding: 30px 10px;
    }
    .filterlist__result .show-in-map {
        padding-right: 20px;
    }
    .filterlist__result .show-detail {
        padding-left: 20px;
    }
    .filterrow .form-control-item {
        margin-top: 0;
    }
}