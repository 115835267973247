.poi__link {
    min-height: 50px;
    padding: 20px;
    cursor: pointer;
}
.poi__circle {
    border-radius: 100%;
    border: 5px solid;
    width: 35px;
    height: 35px;
    line-height: 22px;
    transition: 200ms ease-in;
}
.poi__circle .poi__circle--icon{
    display: none;
    top: 2px;
    position: relative;
}
.active-in-map {
    cursor: pointer;
}
.active-in-map.is-active .poi__circle--text {
    display: none;
}
.active-in-map.is-active .poi__circle--icon {
    display: inline-block;
}
@media screen and (min-width: 768px){
    .poi__link {
        padding: 20px 0;
    }
}

/*Top City Pois*/
.top-city-poi__img {
    position: relative;
}
.top-city-poi__img .icon {
    position: absolute;
    bottom: 8px;
    left: 8px;
}

/*Poi Colors*/
.poi-standard {
    border-color: #009BDC;
}
.poi-aktiv {
    border-color: #0082A5;
}
.poi-bek {
    border-color: #00A5A5;
}
.poi-genuss {
    border-color: #73BE8C;
}
.poi-gesundheit {
    border-color: #69A0BE;
}
.poi-kultur {
    border-color: #7D82B9;
}
.poi-natur {
    border-color: #00A078;
}