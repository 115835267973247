.filter-bar {
    padding: 12px 30px;
    box-shadow: 0 1px 4px rgba(0,0,0,.1);
    background: #fff;
    transition: background 250ms ease-in-out, color 250ms ease-in-out;
    min-height: 64px;
}
@media screen and (max-width: 767px) {
    .filter-bar {
        padding: 15px;
        position: fixed;
        top: 42px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: transform 250ms ease-in-out;
        z-index: 6;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .is-open.filter-bar {
        transform: translateX(0);
    }
}
.filter-bar__item {
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    .filter-bar__item {
        padding: 5px;
        border-radius: 30px;
        transition: background 250ms ease-in-out;
    }
    .filter-bar__item.is-active,
    .filter-bar__item.is-active a:hover {
        background: #009bdc;
        color: #fff;
    }
}
@media screen and (min-width: 1200px) {
    .filter-bar__item {
        font-size: 16px;
        padding: 5px 20px;
    }
}
.filter-bar__item__title:hover {
    color: #009bdc;
}
.filter-bar__item__title__icon {
    font-size: 11px;
    margin-left: 3px;
}
@media screen and (min-width: 1200px) {
    .filter-bar__item__title__icon {
        font-size: 13px;
        margin-left: 5px;
    }
}
.filter-bar__item__dropdown{
    padding: 15px;
    border-radius: 0;
    border: 0;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.5);
    margin-top: 8px;
    color: #404040;
}
@media screen and (min-width: 768px) {
    .filter-bar__item__dropdown {
        min-width: 230px;
    }
}


.filter-bar__input {
    background: #ffffff;
}

/* affix */
.filter-bar.affix {
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #009bdc;
    color: #fff;
}
@media screen and (min-width: 992px){
    .filter-bar.affix {
        top: 70px;
    }
}
.filter-bar.affix .filter-bar__item__title:hover {
    color: #fff;
}
.filter-bar.affix .filter-bar__submit {
    background: #fff;
    color: #009bdc;
}
@media screen and (min-width: 768px) {
    .filter-bar.affix .filter-bar__item.is-active,
    .filter-bar.affix .filter-bar__item.is-active a:hover {
        background: #fff;
        color: #009bdc;
    }
}

/*affix mobile bar*/
.mobile-affix-bar {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    z-index: 7;
}
.mobile-affix-bar__btn {
    color: #666666;
    padding: 10px 15px;
    display: block;
    background: #fff;
    position: relative;
}
.col + .col .mobile-affix-bar__btn {
    border-left: 1px solid #E6E6E6;
}
.icon.mobile-affix-bar__btn__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 13px;
    transition: transform 200ms ease-in-out;
}
.is-open .icon.mobile-affix-bar__btn__icon {
    transform: translateY(-50%) rotate(-45deg);
}
.mobile-affix-bar.affix,
.mobile-affix-bar.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.mobile-affix-bar__btn__hide,
.is-open .mobile-affix-bar__btn__show {
    display: none;
}
.is-open .mobile-affix-bar__btn__hide {
    display: inline-block;
}