.temperature__value {
    font-size: 80px;
    line-height: 1;
    display: inline-block;
    padding-right: 43px;
    position: relative;
}
.temperature--sm .temperature__value {
    font-size: 55px;
    padding-right: 30px;
}
@media screen and (min-width: 768px) {
    .temperature__value {
        font-size: 100px;
    }
    .temperature--sm .temperature__value {
        font-size: 70px;
        padding-right: 43px;
    }
}
.temperature__value__small{
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 10px;
}
.temperature__value__small--min {
    top: auto;
    bottom: 8px;
}
.temperature--sm .temperature__value__small {
    font-size: 18px;
    top: 8px;
}
.temperature--sm.temperature--sm .temperature__value__small--min {
    top: auto;
    bottom: 6px;
}
@media screen and (min-width: 768px) {
    .temperature__value__small{
        font-size: 25px;
        top: 15px;
    }
    .temperature__value__small--min {
        top: auto;
        bottom: 10px;
    }

    .temperature--sm .temperature__value__small {
        font-size: 20px;
        top: 10px;
    }
}