/*Backgrounds*/
.bg-white {
    background-color: #fff;
}
.bg-primary {
    background-color: #009bdc;
}
.bg-secondary {
    background-color: #0083d0;
}
.bg-green-light {
    background-color: #73be8c;
}
.bg-green-dark {
    background-color: #00a078;
}

.bg-blue-gradient {
    background: #00a6e0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwODZjZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc4JSIgc3RvcC1jb2xvcj0iIzAwYTZlMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top,  #0086cf 0%, #00a6e0 78%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0086cf), color-stop(78%,#00a6e0));
    background: -webkit-linear-gradient(top,  #0086cf 0%,#00a6e0 78%);
    background: -o-linear-gradient(top,  #0086cf 0%,#00a6e0 78%);
    background: -ms-linear-gradient(top,  #0086cf 0%,#00a6e0 78%);
    background: linear-gradient(to bottom,  #0086cf 0%,#00a6e0 78%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0086cf', endColorstr='#00a6e0',GradientType=0 );
}
.bg-secondary-blue-gradient {
    background: #71a6c2;
}

/*Links*/
a.text-blue:hover {color: #0082a5;}

/*Font color*/
.text-primary {color: #404040;}
.text-white {color: #fff;}
.text-grey {color: #87878c;}
.text-grey-secondary {color: #9a9a9a;}
.text-grey-light {color: #c3c3c5;}
.text-red {color: #e6001e;}
.text-red-dark {color: #b40a19;}
.text-blue{color: #009bdc;}
.text-blue-dark{color: #0082a5;}
.text-purple{color: #910f7d;}
.text-green-light{color: #73be8c;}
.text-green{color: #00a078;}

/*Font style*/
.italic {font-style: italic;}
.light {font-weight: 400; font-family: 'SourceSansPro-Light';}
.strong {font-weight: 400; font-family: 'SourceSansPro-Semibold';}
.icon.strong { font-family: iconfont; font-weight: 600; }

/*Margins*/
.no-margin.no-margin {
    margin: 0;
}
.ml-auto { margin-left: auto;}
.ml-1 { margin-left: .25rem;}
.ml-2 { margin-left: .5rem;}
.mt-1 { margin-top: .25rem; }
.mt-2 { margin-top: .5rem; }
.mt-3{ margin-top: 1rem; }

.mt0 {margin-top: 0;}
.mb10{margin-bottom: 10px;}
.mb20{margin-bottom: 20px;}
.mb30{margin-bottom: 30px;}
.mr30{margin-right: 30px;}
.mt10{margin-top: 10px;}
.mt20{margin-top: 20px;}
.mr5{margin-right: 5px;}
.mr10{margin-right: 10px;}

/*paddings*/
.pb1{padding-bottom: 1px;}
.pt10{padding-top: 10px;}
.pb10{padding-bottom: 10px;}
.pb20{padding-bottom: 20px;}
.pl20{padding-left: 20px;}
.pt40{padding-top: 25px;}
.pl30{padding-left: 20px;}
.pr30{padding-right: 20px;}
@media screen and (min-width: 768px) {
    .pl30{padding-left: 30px;}
    .pr30{padding-right: 30px;}
    .pt40{padding-top: 40px;}
}

/*embed responsive*/
.embed-responsive { width: 100%; }
.embed-responsive.embed-responsive-1by1 {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-svg {
    padding-top: calc(605/590*100%);
}
.embed-responsive.embed-responsive-16by7{
    padding-top: calc(7/16*100%);
}
.embed-responsive.embed-responsive-8by2{
    padding-top: calc(2/8*100%);
}
.embed-responsive.embed-responsive-8by5 {
    padding-top: calc(5/8*100%);
}
.embed-responsive.embed-responsive-16by5{
    padding-top: calc(5/16*100%);
}

.embed-responsive.embed-responsive-125{
    padding-top: 125.2%;
}

.embed-responsive.embed-responsive-8by3 {
    padding-top: calc(3/8*100%);
}
.embed-responsive.embed-responsive-9by5 {
    padding-top: calc(5/9*100%);
}
.embed-responsive.embed-responsive-14by3{
    padding-top: calc(3/14*100%);
}
.embed-responsive.embed-responsive-5by6{
    padding-top: calc(6.25/5*100%); /*125*/
}
.embed-responsive.embed-responsive-7by2 {
    padding-top: calc(2/7*100%);
}
.embed-responsive.embed-responsive-portal-slider{
    padding-top: calc(7/16*100%);
}
.embed-responsive.embed-responsive-14by6 {
    padding-top: calc(6/14*100%);
}
.embed-responsive.embed-responsive-12by5 {
    padding-top: calc(5/12*100%);
}
.embed-responsive.embed-responsive-gallery-grid {
    padding-top:69%;
}
.embed-responsive.embed-responsive-2by1 {
    padding-top: 50%;
}
.embed-responsive.embed-responsive-3by1{
    padding-top: calc(1/3*100%);
}

.embed-responsive.embed-responsive-6by9{
    padding-top: 150%;
}
.embed-responsive.embed-responsive-5by2 {
    padding-top: calc(2/5*100%);
}
.embed-responsive.embed-responsive-13by5 {
    padding-top: calc(5/13*100%);
}
/*Change Embed Responsive*/
@media screen and (max-width: 767px){
    .embed-responsive--change.embed-responsive-7by2 {
        padding-top: 50%;
    }
    .embed-responsive--change.embed-responsive-5by6{
        padding-top: 100%;
    }
    .embed-responsive--change.embed-responsive-14by3{
        padding-top: 30%;
    }
    .embed-responsive.embed-responsive-portal-slider{
        padding-top: calc(9/16*100%);
    }
    .weather-block .embed-responsive--change{
        padding-top: 50%;
    }
    .embed-responsive.embed-responsive--auto-xs{
        padding: 0;
        height: auto;
    }
    .embed-responsive.embed-responsive--auto-xs > .embed-responsive-item {
        position: relative;
    }

    .embed-responsive.embed-responsive-10by6-xs {
        padding-top: calc(6/10*100%);
    }
}

/*Row Gutter*/

.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row--gutter-width-0>.col {
    padding-left:0;
    padding-right:0;
}

.row--gutter-with-1 {
    margin-right: -.25px;
    margin-left: -.25px;
}
.row--gutter-with-1 .col {
    padding-left: .5px;
    padding-right: .5px;
}
@media screen and (min-width: 768px){
    .row--gutter-with-1 {
        margin-right: -.5px;
        margin-left: -.5px;
    }
}
.row--gutter-with-30 {
    margin-right: -15px;
    margin-left: -15px;
}
.row--gutter-with-30 .col {
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (min-width: 768px){
    .row--gutter-with-60 {
        margin-left: -30px;
        margin-right: -30px;
    }
    .row--gutter-with-60 .col{
        padding-left: 30px;
        padding-right: 30px;
    }
}
.row--gutter-with-36 {
    margin-left: -18px;
    margin-right: -18px;
}
.row--gutter-with-36>.col {
    padding-left: 18px;
    padding-right: 18px;
}

.isClickable {cursor: pointer;}
.d-ib{display: inline-block;}
.text-middle {vertical-align: middle;}
.text-underline {text-decoration: underline;}
a.text-underline:hover {text-decoration: none;}

/*Divider*/
.divider{
    border-bottom: 1px solid #fff;
    margin-bottom: 10px;
}
.divider-grey {
    border-bottom: 1px solid #c3c3c5;
}
.divider-left {
    border-left: 1px solid #c3c3c5;
}


/*tehme circle*/
.theme__circle {
    padding: 5px;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    line-height: 24px;
}

/*Link with arrow*/
.link-with-arrow .icon-arrow {
    font-size: 12px;
    padding-left: 5px;
}
/*Center col*/
.center-col {
    text-align: left;
}

/*Arrange*/
.arrange--xs {
    display: table;
    min-width: 100%;
    table-layout: auto;
}
.arrange__item--xs {
    display: table-cell;
    vertical-align: middle;
    float: none;
}
.arrange__item--bottom--xs {
    vertical-align: bottom;
}
@media screen and (min-width: 768px){
    .arrange {
        display: table;
        min-width: 100%;
        table-layout: auto;
    }
    .arrange__item {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }
    .arrange__item--bottom {
        vertical-align: bottom;
    }

    /*Center cols*/
/*    .row--inline-block {
        font-size: 0;
        text-align: center;
    }
    .row--inline-block>.center-col {
        display: inline-block;
        float: none;
        font-size: 1rem;
        vertical-align: top;
        text-align: left;
    }*/

    /*/!*Center Cols*!/*/
    /*.center-col {*/
        /*display: inline-block;*/
        /*float: none;*/
        /*text-align: left;*/
        /*/!* inline-block space fix *!/*/
        /*margin-right: -2.6px;*/
    /*}*/
}

.position-relative {
    position: relative;
}