/* new styling for mobile friendly season overview*/
@media screen and (max-width: 767px) {
    .recommend-seasons{
        position: relative;
        overflow: hidden;
    }
    .recommend-seasons:after {
        pointer-events: none;
        content: '';
        position: absolute;
        right: -1px;
        top: 0;
        bottom: 0;
        width: 10%;
        z-index: 1;
        background: rgba(250, 250, 250, 0.5);
        background: -moz-linear-gradient(left,  rgba(250,250,250,0) 0%, rgba(250,250,250,.85) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(250,250,250,0) 0%,rgba(250,250,250,.85) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(250,250,250,0) 0%,rgba(250,250,250,.85) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fafafa', endColorstr='#fafafa',GradientType=1 ); /* IE6-9 */
    }
    .recommend-seasons:before {
        pointer-events: none;
        content: "\E002";
        font-family: iconfont;
        speak: none;
        font-style: normal;
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        font-size: 18px;
        font-weight: bold;
    }

    .recommend-seasons .row--gutter-with-1{
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }

    .recommend-seasons .col{
        float: none;
        display: inline-block;
    }
    .recommend-seasons .recommend-seasons__item{
        height: 105px;
        width: 75px;
    }

    .recommend-seasons .recommend-seasons__percent{
        width: 50%;
        position: absolute;
        left: 25%;
        bottom: 35px;
        height: 70px;
        transform: none;
    }
    .recommend-seasons .recommend-seasons__color{
        height: 10px;
        width: 100%;
        position: absolute;
        float:left;
        margin-right: 0;
        bottom: 25px;
    }
    .recommend-seasons .recommend-seasons__text{
        position: absolute;
        float: left;
        width: 100%;
        bottom: 0;
    }

}

.recommend-seasons__item {
    height: 40px;
    position: relative;
}
.recommend-seasons__percent{
    width: 20px;
    position: absolute;
    left: 70%;
    bottom: -45px;
    height: 150px;
    transform: rotate(90deg);
}
.recommend-seasons__percent--percent {
    background: #c3c3c5;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.recommend-seasons__color {
    height: 20px;
    width: 20px;
    position: relative;
    float: left;
    margin-right: 25px;
}
.recommend-seasons__text {
    position: relative;
    float: left;
}
@media screen and (min-width: 768px) {
    .recommend-seasons__item {
        height: 105px;
    }
    .recommend-seasons__percent{
        width: 50%;
        left: 25%;
        bottom: 35px;
        height: 70px;
        transform: none;
    }
    .recommend-seasons__color {
        height: 10px;
        width: 100%;
        position: absolute;
        bottom: 25px;
        margin-right: 0;
    }
    .recommend-seasons__text {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}
/*Colors*/
.recommend-seasons__item.january .recommend-seasons__color {
    background: #007581;
}
.recommend-seasons__item.february .recommend-seasons__color {
    background: #007d55;
}
.recommend-seasons__item.march .recommend-seasons__color {
    background: #008232;
}
.recommend-seasons__item.april .recommend-seasons__color {
    background: #73ad1b;
}
.recommend-seasons__item.may .recommend-seasons__color {
    background: #c8d634;
}
.recommend-seasons__item.june .recommend-seasons__color {
    background: #eadd14;
}
.recommend-seasons__item.july .recommend-seasons__color {
    background: #ffce00;
}
.recommend-seasons__item.august .recommend-seasons__color {
    background: #ff9200;
}
.recommend-seasons__item.september .recommend-seasons__color {
    background: #c80000;
}
.recommend-seasons__item.october .recommend-seasons__color {
    background: #812068;
}
.recommend-seasons__item.november .recommend-seasons__color {
    background: #005faf;
}
.recommend-seasons__item.december .recommend-seasons__color {
    background: #007581;
}