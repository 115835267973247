.navbar-main {
    border-radius: 0;
    border: 0;
    background-color: #fff;
    margin-bottom: 0px;
    z-index: 3;
    color: #666666;
    line-height: 22px;
    display: block;
}
.navbar-nav {
    text-transform: uppercase;
}
.navbar-header {
    position: relative;
    z-index: 1;
}
.nav-toplinks a:hover {
    color: #404040;
}
@media screen and (min-width: 768px){
    .navbar-main {
        box-shadow: 0 1px 4px rgba(0,0,0,.1);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
    .navbar-collapse {
        padding-top: 16px;
    }
    .navbar-brand {
        height: 126px;
        padding: 15px 15px 15px 22px;
    }
    .navbar-nav>li {
        font-size: 22px;
        font-weight: 400;
        font-family: 'SourceSansPro-Semibold';
    }
    .navbar-nav>li>a {
        padding: 18px 14px 12px;
        border-bottom: 6px solid transparent;
    }
    .navbar-nav .second-line {
        margin: 0;
        font-weight: 400;
        font-family: 'SourceSansPro-Regular';
        font-size: 12px;
    }
    .navbar-main>li>a{
        border-bottom: 6px solid #fff;
    }
    .nav>li:focus>a, .nav>li:hover>a, .nav>li.active>a, .nav>li>a:focus, .nav>li.is-open>a {
        background: transparent;
        border-bottom: 6px solid #e6001e;
    }
    .nav-toplinks{
        padding-top: 12px;
        height: 34px;
    }
    .nav-toplinks a {
        padding: 0 10px;
    }
}
.navbar__buttons {
    position: fixed;
    bottom: 20px;
    right: 0;
    left: 0;
    pointer-events: none;
    z-index: 5;
    padding-right: 72px;
}
@media screen and (min-width: 768px) {
    .navbar__buttons {
        bottom: auto;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-30px);
        max-width: 1280px;
        width: 100%;
        padding-right: 40px;
    }
}
@media screen and (min-width: 1200px) {
    .navbar__buttons {
        transform: translateX(-50%) translateY(-36px);
    }
}
.navbar__buttons .open-search {
    pointer-events: initial;
}
@media screen and (min-width: 768px) {
    .navbar__buttons .open-search {
        width: 60px;
        height: 60px;
        left: auto;
        display: inline-block;
        transform: none;
        bottom: -4px;
        right: auto;
        margin-left: 14px;
    }
    .navbar__buttons .open-search.is-open {
        transform: translateY(-75px);
    }
    .affix .navbar__buttons .open-search.is-open {
        transform: translateY(-20px);
    }
    .affix .navbar__buttons .open-search {
        transform: none;
    }
}
@media screen and (min-width: 1200px) {
    .navbar__buttons .open-search.is-open {
        transform: translateY(-60px);
    }
    .affix .navbar__buttons .open-search.is-open {
        transform: translateY(-5px);
    }
    .navbar__buttons .open-search {
        bottom: 0;
    }
}


.nav-toplinks--flex {
    display: flex;
}
.nav-toplinks__list {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.nav-toplinks__list__icon {
    vertical-align: -0.15em;
    margin-right: 3px;
}
.nav-toplinks__list > li {
    position: relative;
}
.nav-toplinks__list > li a {
    padding: 0;
}
.nav-toplinks__list > li:not(:last-child) {
    margin-right: 30px;
}
.nav-toplinks__list--bordered > li:not(:last-child) {
    padding-right: 22px;
    margin-right: 22px;
}
.nav-toplinks__list--bordered > li:not(:last-child):after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 2px;
    background: #666666;
}