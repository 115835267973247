.usp {
    font-size: 20px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SourceSansPro-Semibold';
    font-weight: 400;
}

.usp-faded {
    opacity: 0.2;
}

@media screen and (min-width: 768px) {
    .usp {
        font-size: 24px;
    }
}
.usp + .usp {
    margin-top: 30px;
}
.usp__icon {
    font-size: 24px;
    margin-right: 8px;
}
@media screen and (min-width: 768px) {
    .usp__icon {
        font-size: 30px;
        margin-right: 15px;
    }
}

/*_________USP Region Style________*/
.usp-box-wrapper--region {
    display: flex;
    justify-content: center;
}
.usp--region {
    justify-content: left;
} 
.usp__icon--region {
    min-width: 30px;
}
@media screen and (min-width: 768px) {
    .usp__icon--region {
        min-width: 35px;
    }
}