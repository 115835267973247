.img-slider {
    padding: 0 30px;
    margin: 30px 0;
}
@media screen and (min-width: 768px){
    .img-slider {
        padding: 0 70px;
        margin: 55px 0 45px;
    }
}
.img-slider .img-slider__arrow {
    position: absolute;
    top: 58px;
    font-size: 22px;
    font-weight: 700;
    right: 0;
}
@media screen and (min-width: 768px){
    .img-slider .img-slider__arrow {
        top: 118px;
        right: 30px;
    }
}
.img-slider .img-slider__arrow.slick-prev {
    left: 0;
    right: auto;
}
@media screen and (min-width: 768px){
    .img-slider .img-slider__arrow.slick-prev {
        left: 30px;
        right: auto;
    }
}
.img-slider__item__meta {
    font-size: 13px;
    margin-top: 10px;
}