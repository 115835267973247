@media screen and (min-width: 768px){
    .navbar-main.affix {
        height: 70px;
        width: 100%;
        position: fixed;
    }
    .navbar-main.affix .form-group .navbar-brand {
        height: 126px;
        background-image: none;
        width: auto;
        padding: 15px 15px 15px 22px;
        margin-left: -15px;
        margin-top: 0;
    }
    .navbar-main.affix .form-group .navbar-brand>img {
        display: block;
    }
    .navbar-main.affix .second-line {
        display: none;
    }
    .navbar-main.affix .navbar-nav>li>a {
        font-size: 18px;
        padding-bottom: 9px;
        padding-top: 10px;
    }
    .navbar-main.affix .navbar-collapse {
        padding-top: 5px;
    }
    .navbar-main.affix .navbar-brand {
        height: 50px;
        padding: 0 25px;
        background-image: url('/static/img/logo_affix.png');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 82px;
        margin-left: 0;
        margin-top: 10px;
    }
    .navbar-main.affix .navbar-brand>img {
        display: none;
    }
    .navbar-main.affix .nav-toplinks {
        padding: 5px 110px 0 95px;
        margin-bottom: -7px;
        height: 27px;
    }
}