.img-with-overlay:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.img-secondary-overlay--black:after {
    background: #000000;
    opacity: 0.5;
    transition: opacity 200ms ease-in;
}
.img-overlay--black:after {
    background: #ffffff;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmNmYyIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  transparent 0%, #000000 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,transparent), color-stop(100%,#000000));
    background: -webkit-linear-gradient(top,  transparent 0%,#000000 100%);
    background: -o-linear-gradient(top,  transparent 0%,#000000 100%);
    background: -ms-linear-gradient(top,  transparent 0%,#000000 100%);
    background: linear-gradient(to bottom,  transparent 0%,#000000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#000000',GradientType=0 );
    opacity: 0.5;
    -moz-transition: opacity 200ms ease-in-out;
    -webkit-transition: opacity 200ms ease-in-out;
    -o-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out;
    will-change: opacity;
}
.img-overlay--black:hover:after {
    opacity: 0;
}
.img-overlay--blue:after {
    background: #009bdc;
    opacity: 0.9;
}
.img-overlay--turquoise:after {
    background: #00a5a5;
    opacity: 0.9;
}
.img-overlay--green:after {
    background: #73be8c;
    opacity: 0.5;
}
.img-with-overlay.img-circle:after {
    border-radius: 100%;
}

.isClickable:hover .img-overlay--black:after, .isClickable:hover .img-secondary-overlay--black:after {
    opacity: 0;
}