/*Content Head*/
.content-head{
    position: relative;
    height: 140px;
}
.content-head--text {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.content-head--text>p{
    margin-bottom: 0
}

.content-head--text__btn {
    position: absolute;
    right: 0;
    bottom: 0;
}

.content-head--text-max {
    max-width: 940px;
}
/*Fixed Content Head*/
.content-head--fix {
    height: 150px;
    position: absolute;
    width: 100%;
}
@media screen and (min-width: 768px) {
    .content-head {
        height: 240px;
    }
    .content-head--fix {
        height: 290px;
    }
    .content-head--text h1{
        font-size: 65px;
    }
}



/*Content Head Background colors*/
.bg-poi { background-color: rgb(0,156,218); }
.bg-aktiv { background-color: rgb(0,130,164); }
.bg-bek  { background-color: rgb(0,165,167); }
.bg-natur  { background-color: rgb(0,162,118); }
.bg-genuss  { background-color: rgb(113,188,139); }
.bg-gesundheit  { background-color: rgb(103,162,192); }
.bg-kultur  { background-color: rgb(124,132,187); }

/*Content Head Background gradients*/
.bg-poi-gradient {
    background: #0089d3;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwODlkMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGE4ZTEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #0089d3 0%, #00a8e1 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0089d3), color-stop(100%,#00a8e1));
    background: -webkit-linear-gradient(top,  #0089d3 0%,#00a8e1 100%);
    background: -o-linear-gradient(top,  #0089d3 0%,#00a8e1 100%);
    background: -ms-linear-gradient(top,  #0089d3 0%,#00a8e1 100%);
    background: linear-gradient(to bottom,  #0089d3 0%,#00a8e1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0089d3', endColorstr='#00a8e1',GradientType=0 );
}
.bg-aktiv-gradient {
    background: #006f93;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNmY5MyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDkyYjEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #006f93 0%, #0092b1 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#006f93), color-stop(100%,#0092b1));
    background: -webkit-linear-gradient(top,  #006f93 0%,#0092b1 100%);
    background: -o-linear-gradient(top,  #006f93 0%,#0092b1 100%);
    background: -ms-linear-gradient(top,  #006f93 0%,#0092b1 100%);
    background: linear-gradient(to bottom,  #006f93 0%,#0092b1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006f93', endColorstr='#0092b1',GradientType=0 );
}
.bg-bek-gradient {
    background: #009393;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwOTM5MyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGIxYjEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #009393 0%, #00b1b1 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#009393), color-stop(100%,#00b1b1));
    background: -webkit-linear-gradient(top,  #009393 0%,#00b1b1 100%);
    background: -o-linear-gradient(top,  #009393 0%,#00b1b1 100%);
    background: -ms-linear-gradient(top,  #009393 0%,#00b1b1 100%);
    background: linear-gradient(to bottom,  #009393 0%,#00b1b1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009393', endColorstr='#00b1b1',GradientType=0 );
}
.bg-natur-gradient {
    background: #009065;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwOTA2NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGFlODgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #009065 0%, #00ae88 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#009065), color-stop(100%,#00ae88));
    background: -webkit-linear-gradient(top,  #009065 0%,#00ae88 100%);
    background: -o-linear-gradient(top,  #009065 0%,#00ae88 100%);
    background: -ms-linear-gradient(top,  #009065 0%,#00ae88 100%);
    background: linear-gradient(to bottom,  #009065 0%,#00ae88 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009065', endColorstr='#00ae88',GradientType=0 );
}
.bg-genuss-gradient {
    background: #60b079;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYwYjA3OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4NGM3OWIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #60b079 0%, #84c79b 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#60b079), color-stop(100%,#84c79b));
    background: -webkit-linear-gradient(top,  #60b079 0%,#84c79b 100%);
    background: -o-linear-gradient(top,  #60b079 0%,#84c79b 100%);
    background: -ms-linear-gradient(top,  #60b079 0%,#84c79b 100%);
    background: linear-gradient(to bottom,  #60b079 0%,#84c79b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60b079', endColorstr='#84c79b',GradientType=0 );
}
.bg-gesundheit-gradient {
    background: #568eb0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzU2OGViMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM3YmFkYzciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #568eb0 0%, #7badc7 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#568eb0), color-stop(100%,#7badc7));
    background: -webkit-linear-gradient(top,  #568eb0 0%,#7badc7 100%);
    background: -o-linear-gradient(top,  #568eb0 0%,#7badc7 100%);
    background: -ms-linear-gradient(top,  #568eb0 0%,#7badc7 100%);
    background: linear-gradient(to bottom,  #568eb0 0%,#7badc7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#568eb0', endColorstr='#7badc7',GradientType=0 );
}
.bg-kultur-gradient {
    background: #6a6faa;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZhNmZhYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4ZDkyYzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #6a6faa 0%, #8d92c3 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6a6faa), color-stop(100%,#8d92c3));
    background: -webkit-linear-gradient(top,  #6a6faa 0%,#8d92c3 100%);
    background: -o-linear-gradient(top,  #6a6faa 0%,#8d92c3 100%);
    background: -ms-linear-gradient(top,  #6a6faa 0%,#8d92c3 100%);
    background: linear-gradient(to bottom,  #6a6faa 0%,#8d92c3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a6faa', endColorstr='#8d92c3',GradientType=0 );
}

.bg-home-gradient {
    background: #059ddc;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZhNmZhYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4ZDkyYzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #059ddc 0%, #6dbe8f 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#059ddc), color-stop(100%,#6dbe8f));
    background: -webkit-linear-gradient(top,  #059ddc 0%,#6dbe8f 100%);
    background: -o-linear-gradient(top,  #059ddc 0%,#6dbe8f 100%);
    background: -ms-linear-gradient(top,  #059ddc 0%,#6dbe8f 100%);
    background: linear-gradient(to bottom,  #059ddc 0%,#6dbe8f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#059ddc', endColorstr='#6dbe8f',GradientType=0 );
}