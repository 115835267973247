.ripple-circle {
    display: block;
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    width: 100%;
    height: 100%;
}

/*Colors*/
.ripple-circle--blue {
    background: rgba(0, 166, 224, 0.3);
}


/*Animation*/
.animate {
    -webkit-animation: ripple 0.65s linear;
    -moz-animation: ripple 0.65s linear;
    -ms-animation: ripple 0.65s linear;
    -o-animation: ripple 0.65s linear;
    animation: ripple 0.65s linear;
}

@-webkit-keyframes
ripple {  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
}
}

@-moz-keyframes
ripple {  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
}
}

@-o-keyframes
ripple {  100% {
    opacity: 0;
    -o-transform: scale(2.5);
}
}

@keyframes
ripple {  100% {
    opacity: 0;
    transform: scale(2.5);
}
}