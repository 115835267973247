.nav-tabs {
    padding: 0;
    border-bottom: 0;
    margin-bottom: 0;
    box-shadow: 0 2px 4px 0 #dbdbdb;
}
.nav-tabs .btn {
    border: 0;
    padding: 10px 5px;
}
.nav-tabs.btn-group>.btn:hover{
    z-index: auto;
}
.nav-tabs .btn-green-light:hover {
    background: #73be8c;
}
.nav-tabs .active-state {
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 4px;
    width: 100%;
    background: #fff;
    z-index: 3;
    transition: 200ms ease;
}
.tab-pane {
    display: none;
}
.tab-pane.active {
    display: block;
}
.btn-group--tabs .btn {
    opacity: 0.5;
    transition: 200ms ease-in;
}
.btn-group--tabs .btn.active {
    opacity: 1;
}

/*button nav tabs*/
.nav-tabs--btn {
    box-shadow: none;
    margin-top: 30px;
    margin-bottom: 25px;
}
@media screen and (min-width: 768px){
    .nav-tabs--btn {
        margin-top: 50px;
        margin-bottom: 37px;
    }
}
.nav-tabs.nav-tabs--btn li > a {
    color: #009bdc;
    border: 1px solid #009bdc;
    border-radius: 0;
    margin-right: -1px;
    padding: 9px 15px;
    font-size: 14px;
}
.nav-tabs.nav-tabs--btn li.active > a,
.nav-tabs.nav-tabs--btn li.active > a:hover,
.nav-tabs.nav-tabs--btn li.active > a:focus,
.nav-tabs--btn li a:hover,
.nav-tabs.nav-tabs--btn li a:focus,
.nav-tabs.nav-tabs--btn>li:hover>a {
    background-color: #009bdc;
    border: 1px solid #009bdc;
    color: #fff;
}

@media screen and (min-width: 786px){
    .nav-tabs--btn .active a:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateY(100%) translateX(-50%);
        border-style: solid;
        border-width: 9px 9px 0 9px;
        border-color: #009bdc transparent transparent transparent;
    }
}