.pagination {
    margin-bottom: 0;
    margin-top: 0;
}
.paging-row .pagination li a {
    background: none;
    border: 0;
    border-radius: 0 !important;
    color: #7a7a80;
}
.paging-row .pagination li a:hover {
    background: #fff;
}
.paging-row .pagination .active a {
    color: #404040;
    background: #fff;
}
.pagination li a .icon-arrow {
    font-size: 12px;
    font-weight: 700;
    position: relative;
    top: 2px;
    margin-left: 5px;
}
.pagination li:first-child a .icon-arrow {
    transform: rotate(180deg);
    display: inline-block;
    margin-right: 5px;
    margin-left: auto;
}