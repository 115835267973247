.lake-map {
    position: relative;
    padding-bottom: 30px;
}
.lake-poi {
    padding: 20px 15px;
}
.lake-poi .img-circle {
    max-width: 200px;
}
@media screen and (min-width: 768px) {
    .lake-map {
        max-width: 700px;
        height: 365px;
        padding-bottom: 0;
    }
    .lake-poi {
        width: 60px;
        height: 90px;
        position: absolute;
        padding: 0;
    }
    .lake-poi:hover:after {
        background-color: rgba(145, 15, 125, 0.5);
        border-radius: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
    }
    .lake-poi .img-circle {
        max-width: 60px;
        max-height: 60px;
    }
    .lake-poi--line {
        position: absolute;
        top: 58px;
        left: 29px;
        width: 3px;
        height: 22px;
        background: #910f7d;
    }
    .lake-poi--dot {
        position: absolute;
        bottom: 0px;
        height: 11px;
        width: 11px;
        border: 3px solid #910f7d;
        border-radius: 10px;
        left: 25px;
    }
    .lake-btn {
        position: absolute;
    }
    .lake-map .btn-group {
        position: absolute;
        bottom: 0;
        left: 20%;
    }
    .tooltip.top .tooltip-arrow {
        border-top-color: #910F7D;
    }
    .tooltip-inner{
        background-color: #910F7D;
    }
}
@media screen and (min-width: 1200px){
    .lake-map {
        max-width: 960px;
        height: 500px;
    }
    .lake-poi {
        width: 80px;
        height: 116px;
    }
    .lake-poi:hover:after {
        width: 80px;
        height: 80px;
    }
    .lake-poi .img-circle {
        max-width: 80px;
        max-height: 80px;
    }
    .lake-poi--line {
        top: 80px;
        left: 37px;
        width: 4px;
        height: 25px;
    }
    .lake-poi--dot {
        height: 12px;
        width: 12px;
        border: 4px solid #910f7d;
        left: 33px;
    }
    .lake-map .btn-group {
        bottom: 20px;
        left: 34%;
    }
}