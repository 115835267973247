.form-control--special.js-datepicker {
    margin-left: 45px;
    padding-bottom: 0;
    padding-left: 0;
    max-width: 138px;
}
.js-datepicker ~ .form-control-line {
    margin-left: 45px;
    max-width: 138px;
}
.js-datepicker--trigger {
    cursor: pointer;
    position: absolute;
    left: 10px;
    bottom: 0;
    border: 1px solid #c3c3c5;
    padding: 5px;
    font-size: 19px;
}
.ui-datepicker {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
}
.ui-state-default {
    color: #009bdc;
}
.ui-state-disabled .ui-state-default, .ui-state-disabled.ui-datepicker-prev,
.ui-state-disabled.ui-datepicker-next, .ui-state-disabled.ui-datepicker-next:hover, .ui-state-disabled.ui-datepicker-prev:hover {
    color: #87878c;
}
.ui-datepicker-next, .ui-datepicker-prev, .ui-datepicker-next:hover, .ui-datepicker-prev:hover {
    color: #009bdc;
    font-size: 20px;
}
.ui-datepicker .ui-datepicker-next-hover {
    top: 2px;
    right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
    top: 2px;
    left: 2px;
}

.ui-datepicker .ui-datepicker-title select{
    margin-top: 7px;
    border-color: #e4e4e4;
    margin-right: 6px;
}