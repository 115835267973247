.facts {
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
}
@media screen and (min-width: 768px) {
    .facts {
        padding: 50px 0 30px;
    }
    .facts:before {
        content: '';
        background: url("/static/img/line.svg");
        position: absolute;
        left: 30px;
        top: 0;
        bottom: 0;
        width: 48px;
    }
}
@media screen and (min-width: 768px) {
    .facts:before {
        left: 50%;
        transform: translateX(-50%);
    }
}
.facts__step {
    position: relative;
    padding: 15px 0;
    line-height: calc(24/15);
    text-align: center;
}
@media screen and (min-width: 768px) {
    .facts__step {
        text-align: right;
        width: 50%;
        padding: 24px 110px 0 0;
    }
    .facts__step + .facts__step {
        margin-top: -140px;
    }
}
.facts__step__title {
    font-size: 28px;
    margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
    .facts__step__title {
        font-size: 46px;
        margin-bottom: 25px;
    }
}

.facts__step__img {
    max-width: 100px;
    margin: 0 auto 20px;
}
@media screen and (min-width: 768px) {
    .facts__step__img {
        position: absolute;
        margin: 0;
        right: -60px;
        top: 0;
        max-width: 120px;
    }

    /* event items */
    .facts__step:nth-child(even) {
        text-align: left;
        margin-left: 50%;
        padding-left: 110px;
        padding-right: 0;
    }
    .facts__step:nth-child(even) .facts__step__img {
        right: auto;
        left: -60px;
    }
}