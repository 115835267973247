.mfp-zoom-out-cur {
    cursor: default;
}
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer;
}
.mfp-close {
    cursor: pointer;
    font-size: 22px;
}
.mfp-close>.icon {
    pointer-events: none;
}
.asset-video .mfp-close {
    color: #fff;
    top: -40px;
}
.mfp-arrow{
    z-index: 1;
    opacity: 1;
    cursor: pointer;
    width: 60px;
    visibility: hidden;
}
.mfp-s-ready .mfp-arrow {
    visibility: visible;
}
.mfp-arrow .mfp-b, .mfp-arrow:before {
    font-size: 40px;
    opacity: 1;
}
.mfp-arrow .mfp-a, .mfp-arrow .mfp-b, .mfp-arrow:after, .mfp-arrow:before {
    border: 0;
}
.mfp-arrow-left.mfp-arrow-left {
    transform: rotate(180deg);
    left: 60px;
}
.js-lightbox__hover {
    position: relative;
    transform: translateZ(0);
}
.js-lightbox__hover--overlay,
.js-lightbox__hover--overlay:after{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    will-change: opacity;
    transition: opacity 200ms ease;
}

.js-lightbox__hover--overlay.js-lightbox__hover--overlay-video{
    visibility: visible;
    opacity: 1;
}

.js-lightbox__hover--overlay:after {
    content: '';
    opacity: .5;
    background: rgba(0,0,0, .5);
    visibility: visible;
}
.js-lightbox__hover--overlay>.icon {
    position: absolute;
    display: block;
    z-index: 1;
    color: #fff;
    font-size: 80px;
    top: 50%;
    left: 0;
    right: 0;
    height: 80px;
    transform: translateY(-50%);
}


/*Hover*/
.js-lightbox:hover .js-lightbox__hover--overlay,
.js-lightbox__hover:hover .js-lightbox__hover--overlay,
.js-lightbox__hover:active .js-lightbox__hover--overlay {
    visibility: visible;
    opacity: 1;
}
.mfp-content {
    max-width: 1400px;
}
.mfp-counter {
    font-size: 16px;
}
.mfp-title {
    text-align: right;
    font-size: 18px;
    padding-right: 50px;
}

/*Iframe*/
.mfp-iframe-holder .mfp-content {
    max-width: 1680px;
}
.mfp-iframe-scaler {
    padding-top: 56.25%;
}


@media screen and (min-width: 768px) {
    .js-lightbox__hover--overlay>.sd-icon {
        font-size: 74px;
        margin-top: -37px;
    }
    .mfp-arrow-left.mfp-arrow-left {
        left: -15px;
    }
    .mfp-arrow {
        width: 90px;
    }
    .mfp-content {
        margin-left: -18px;
    }

    .js-lightbox__hover--overlay.js-lightbox__hover--overlay-video{
        opacity: 0;
        visibility: hidden;
    }
}
