.navbar-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    transform: translateY(-100%);
    background: #fff;
    transition: transform 200ms ease-in;
}
@media screen and (min-width: 768px) {
    .navbar-overlay {
        min-height: 126px;
        display: flex;
        align-items: center;
    }
}
@media screen and (max-width: 767px) {
    .navbar-overlay {
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}
.navbar-overlay__content {
    padding: 70px 30px;
}
@media screen and (max-width: 767px) {
    .navbar-overlay__content {
        -webkit-overflow-scrolling: touch;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
@media screen and (min-width: 768px) {
    .navbar-overlay__content {
        padding: 25px 15px;
    }
}
.navbar-overlay.is-open {
    transform: translateY(0);
}
.navbar-overlay__close {
    position: absolute;
    top: 10px;
    right: 16px;
    width: 54px;
    height: 54px;
    background: #525252;
    border-radius: 50%;
    color: #fff;
    z-index: 2;
}
@media screen and (min-width: 768px) {
    .navbar-overlay__close {
        right: 10px;
        width: 60px;
        height: 60px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.navbar-overlay__close:hover {
    color: #fff;
}
.navbar-overlay__close .navbar-overlay__close__icon {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
@media screen and (min-width: 768px) {
    .navbar-overlay__close .navbar-overlay__close__icon {
        font-size: 22px;
    }
}

.navbar-search.is-open ~ .navbar__buttons .overlay-toggle {
    opacity: 0;
}